// angular material
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '@angular/material/theming';
@include mat-core();

// angular ui switch
@import '~ngx-ui-switch/ui-switch.component.scss';

@import "variables";
@import "colors";
@import "fonts";

#_mdspbar {
  z-index: 998 !important;
}

#main-container {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  box-shadow: none;
  overflow: auto;
  position: relative;

  &.remove-all-padding {
    min-height: 0 !important;
    // max-height: 0 !important;
    height: auto !important;
    width: auto !important;
  }
}

// toolbar 
.ff-toolbar {
  width: 100%;
  max-width: 100%;
  // overflow-x: auto;
  // overflow-y: hidden;
  // min-height: 72px;
}

// apex-chart
.arrow_box {
  padding: 0.75rem 1rem;
  font-size: 0.8rem;

  p {
    margin: 0;
  }
}

div.arrow_box {
  font-size: $font-size-xxs;
  height: 100%;

  p {
    margin: 0;
    padding: 4px 8px;
  }

}

// radio buttons

.radio_button_disabled svg {

  .out {
    fill: $radio-button-disabled-out;
  }

  .in {
    fill: $radio-button-disabled-in;
  }
}

.radio_button svg {

  .out {
    fill: $radio-button-out;
  }

  .in {
    fill: $radio-button-in;
  }
}

#fullcalendar {
  background: $calendar-bg-color;
  color: $calendar-font-color;
  border-radius: $radius-2;
}


// tabs
.tab-content-animated {
  position: relative;
  overflow: hidden;

  .tab-content {
    height: calc(100% - 0.75rem);
    position: absolute;
    overflow: auto;
  }
}

// translate
.translate-l100 {
  transform: translate(-100%);
}

.translate-r100 {
  transform: translate(100%);
}

// z-index
.z-index-m1 {
  z-index: -1;
}

// plotly modebar
.js-plotly-plot .plotly .modebar {
  z-index: 7 !important;
  padding-right: 16px;
  padding-top: 4px;
}

.updatemenu-item-rect {
  stroke-width: 0.5px !important;
  shape-rendering: auto !important;
  rx: 10;
  ry: 10;

  // fill: $color-secondary !important;
  // fill-opacity: 1 !important;
  // #F4FAFF
}

.updatemenu-item-text {
  /* fill: white; */
  text-transform: none;
  font-weight: 800 !important;
}

.hidePaginator {
  position: fixed !important;
  top: -9999999px !important;
  left: 0 !important;
}

.color-square {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50px;
  margin: 0 8px;
  opacity: 0.6;
  padding: 0;
}

.color-square-padding {
  border-radius: 50px;
  margin: 0 8px;
  opacity: 0.6;
  padding: 0 8px;
}

ul.ff-ul {
  list-style: none;
  padding-inline-start: 0px;
  margin: 6px 0;
}

.mat-tooltip.ff-tooltip {
  font-size: 1rem;
  white-space: pre-wrap;
}

.not-clickable {
  cursor: default !important;
}


.mat-button-wrapper {
  mat-icon {
    line-height: initial;
  }
}

.mat-select-trigger {
  vertical-align: middle;
}

mat-form-field.w140 {
  width: 140px;
  min-width: 140px;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #4d4d4d;
}

ff-export-table {
  // position: absolute;
  // top: 16px;
  // right: 16px;
  margin-right: 12px;
  // z-index: 9999;
  flex-direction: row;
  display: flex;
  place-content: center space-evenly;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;

  button {
    padding: 0 !important;
    margin: 0 !important;
    background: none !important;
    box-shadow: none !important;
    color: inherit !important;
    max-width: 100% !important;
    width: 40px !important;
    min-width: 40px !important;
    height: 40px !important;
  }
}

.snackbar {

  white-space: pre-wrap;
  font-weight: 500;

  &.success {
    background: #4BDD7D;
    color: white;

    .mat-simple-snackbar>span:before {
      margin-right: .5rem;
      content: '\2713';
    }

    .mat-simple-snackbar-action {
      color: white;
    }
  }

  &.fail {
    background: #FC5249;
    color: white;

    .mat-simple-snackbar>span:before {
      margin-right: .5rem;
      font-size: 1.5rem;
      content: '\26A0';
    }

    .mat-simple-snackbar-action {
      color: white;
    }
  }

  &.warning {
    background: #FCA10D;
    color: white;

    .mat-simple-snackbar>span:before {
      margin-right: .5rem;
      content: '\26A0';
    }

    .mat-simple-snackbar-action {
      color: white;
    }
  }

}

.my-tooltip {
  white-space: pre-line;
  font-size: 1.1rem;
}

.ff-disabled {
  opacity: 0.38 !important;
  cursor: not-allowed !important;

  &.completely {
    pointer-events: none !important;
  }
}

html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  background: $main-background;
  font-size: $font-main-size;
}

* {
  font-family: $font-main-family;
}

::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-height;
}

::-webkit-scrollbar-track {
  background: $scrollbar-background;
  border-radius: $radius-1;
  margin-top: $scrollbar-margin-top;
  margin-bottom: $scrollbar-margin-bottom;
}

::-webkit-scrollbar-thumb {
  background: $scrollbar-color;
  border-radius: $radius-1;
  cursor: pointer;
}


/*
USEFUL CLASSES:

The following classes are used everywhere throughout the app
*/

.pb-4 {
  padding-bottom: 4px !important;
}

.h-auto {
  height: auto !important;
}

.padding0 {
  padding: 0 !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.clickable {
  cursor: pointer !important;
  outline: 0 !important;
}

.not-clickable {
  cursor: default !important;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

// pre
pre {
  font-family: monospace;
  font-size: 0.75rem;
}

// transitions
.transition-01 {
  @include transition-01;
}

@mixin fixedIconContainer($size) {
  height: $size;
  width: $size;
  min-height: $size;
  min-width: $size;
  max-height: $size;
  max-width: $size;
}

@mixin defaultWidgetTitle {
  font-weight: 800;
  text-overflow: ellipsis;
  width: 100%;
  overflow-x: clip;
}

.default-widget-title {
  @include defaultWidgetTitle
}

.fw-500 {
  font-weight: 500 !important;
}

.opacity0 {
  opacity: 0;
}

.bg-white {
  background: $color-white;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}






// button
button {
  cursor: pointer;
  outline: none;

  &.button-1 {
    background: $button-background-1;
    color: $button-color-1;
    border-radius: $radius-3;
    border: $button-border;
    padding: 0.5rem 1rem;
  }

  &.rounded-button {
    border-radius: $radius-1;
    border: $button-border;
    padding: 0.5rem;
    min-width: 32px;
    line-height: 0 !important;

    &:disabled {
      opacity: 0.28;
      cursor: not-allowed;
    }

    .mat-icon {
      height: 24px;
      width: 24px;
    }

    &.small {
      @include fixedIconContainer(24px);
      padding: 0;

      .mat-icon {
        font-weight: 800;
        height: $font-size;
        width: $font-size;
      }

      .material-icons {
        font-weight: 800;
        font-size: $font-size;
      }
    }

  }

}

.mat-button {
  line-height: inherit !important;
}

button {
  // &:not(.exclude-transition) {
  //   @include transition-01;
  // }

  font-weight: 500;
}

.active-filters-circle {
  background: red;
  color: white;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -6px;
  right: -6px;
}

.filter-button-no-background {
  padding: 0 0.75rem !important;
  margin: 0 0.75rem 0 0 !important;
  cursor: pointer !important;
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
  border-radius: $radius-3 !important;
  height: $button-s-height !important;
  min-height: $button-s-height;

  .material-icons {
    line-height: 1;
  }

  &:disabled {
    opacity: 0.48;

    &:hover {
      cursor: not-allowed !important;
    }
  }

  .icon {
    width: 1.25rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
}

.circular-icon-button {
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
  outline: none;
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 50px !important;
  height: $button-s-height !important;
  min-height: $button-s-height !important;
  max-height: $button-s-height !important;
  width: $button-s-height !important;
  min-width: $button-s-height !important;
  max-width: $button-s-height !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $filter-button-background;
  color: $filter-button-color;

  .material-icons {
    line-height: inherit;
  }

  &.left {
    margin-right: 8px !important;
  }

  &.right {
    margin-left: 8px !important;
  }

  &:disabled {
    opacity: 0.48;

    &:hover {
      cursor: not-allowed !important;
    }
  }

  .icon {
    width: 1.5rem !important;
    height: 1.5rem !important;
    font-size: 1.5rem !important;
  }
}

.filter-button {
  padding: 0 0.75rem !important;
  margin: 0 0.75rem 0 0 !important;
  background: $filter-button-background;
  cursor: pointer !important;
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
  border-radius: $radius-3 !important;
  height: $button-s-height !important;
  min-height: $button-s-height;
  position: relative;

  .material-icons {
    line-height: 1;
  }

  &:disabled {
    opacity: 0.48;

    &:hover {
      cursor: not-allowed !important;
    }
  }

  .icon {
    width: 1.25rem;
    height: 1.5rem;
    margin: 0 0.5rem 0 0.25rem;
  }

  &.no-label {
    .icon {
      margin-right: 0.25rem;
    }
  }

  .icon,
  .icon svg {
    color: $filter-button-color-icon;
    fill: $filter-button-color-icon;
  }

  span {
    font-size: $font-button-m;
    line-height: $line-height-button-m;
    font-weight: $filter-button-font-weight;
    color: $filter-button-color;
  }
}

@media screen and (max-width: 959px) {

  .filter-button {
    width: 100%;
    margin-bottom: .5rem !important;
  }
}

.help-button {
  padding: 0 0.75rem !important;
  margin: 0 0.75rem 0 0 !important;
  background: $filter-button-background;
  cursor: pointer !important;
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
  border-radius: $radius-3 !important;
  height: $button-xs-height !important;

  .icon {
    width: 1.25rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  .icon,
  .icon svg {
    color: $filter-button-color-icon;
    fill: $filter-button-color-icon;
  }

  span {
    font-size: $font-button-m;
    line-height: $line-height-button-m;
    color: $filter-button-color;
  }
}

ff-export-table {
  border-radius: $radius-3;
  background: $filter-button-background;

  button {
    border-radius: $radius-3 !important;

    .icon,
    .icon svg {
      color: $filter-button-color-icon !important;
      fill: $filter-button-color-icon !important;
    }
  }
}

[class^="ph-"],
[class*=" ph-"] {
  display: inline-flex;
  font-size: 1.5rem;
}

.icon {
  width: 1.5rem;
  // height: 0.5rem;

  &.right {
    margin-left: 4px;
  }

  &.left {
    margin-right: 4px;
  }
}

.mat-icon,
.mat-icons {
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;

  &.hm-grid {
    width: 4.5rem !important;
    height: 4.5rem !important;
  }

  &.logo-icon {
    width: unset;
    height: unset;
  }
}

.material-icons {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.1;
}

.box-icon {
  padding: 0.75rem 0.9rem;
  border-radius: $radius-2;
  display: flex;
  justify-content: center;
  align-items: center;
}

#_mdspcontent {
  position: relative;
  flex: auto;
  order: 2;
  overflow-y: auto;
}

#body-container {

  background: $main-background;
  overflow: hidden;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;

  &.remove-all-padding {
    min-height: 0 !important;
    height: auto !important;
  }
}

.sidenav-container {
  min-height: 100vh;
}

.mindsphere {

  &#body-container {
    height: calc(100vh - 44px);
    min-height: calc(100vh - 44px);
    max-height: calc(100vh - 44px);

    &.remove-all-padding {
      min-height: 0 !important;
      max-height: 0 !important;
      height: auto !important;
    }
  }

  #main-container,
  .sidenav-container {
    min-height: calc(100vh - 44px);

    &.remove-all-padding {
      min-height: 0 !important;
    }
  }

}

#main-content {
  margin: $main-toolbar-height 1.25rem 1.25rem 0.75rem;
  padding: 0.75rem;
  border-radius: $radius-1;
  background: $secondary-background;

  &.remove-all-padding {
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 1440px) {

  #body-container {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  #main-container,
  .sidenav-container {
    min-height: 100%;
  }

  .mindsphere {

    &#body-container {
      height: 100%;
      min-height: 100%;
      max-height: 100%;
    }

    #main-container,
    .sidenav-container {
      min-height: 100%;
    }
  }

  #main-content {
    margin: $main-toolbar-height 0.75rem 0.75rem 0.75rem;
    padding: 0;
  }
}

.tab-content {
  width: 100%;
  // margin-top: 0.75rem;
  padding-top: 1rem;
  background: $tab-content-background;
  // border-top: 1px solid $tab-content-border-color;
  // border-radius: $radius-1;

  &.without-tabs {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}

table {
  td {
    font-weight: 400 !important;
  }

  td.primary {
    font-weight: 700 !important;
  }

  td.td-icon {
    width: 1rem;
    padding: 0.5rem 1rem;
  }

  td.td-icon-large {
    width: 7rem;
    padding: 0.5rem 1rem;
  }

  th .icon {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
  }

  td .td-list {
    padding: 4px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li>div {
        border-bottom: 1px solid $line-color-border;
        padding-top: 4px;
      }
    }
  }
}

.mat-card {
  box-shadow: none !important;
  background: $color-white;
  border-radius: $radius-2 !important;
  color: $widget-value-span-font-color;
}

.mat-table {
  background: $color-white;
}

.mat-paginator-container {
  background: $color-white;
  color: $widget-value-span-font-color;
}

// search-input
.search-input {
  background: $search-input-bg-color;
  padding: 0 0.75rem;
  border-radius: $radius-2;
  color: $search-input-font-color;
  height: 100%;

  .mat-icon {
    color: $search-input-font-color;
  }

  input {
    color: $search-input-font-color;
    background: $search-input-bg-color;
    margin-left: 4px;
    height: 2.5rem;
    border: none;
    outline: none;

    &::placeholder {
      color: $search-input-font-color;
    }
  }

  &.inverted {

    background: $search-input-font-color;
    padding: 0 0.75rem;
    border-radius: $radius-2;
    color: $search-input-bg-color;
    height: 100%;

    .mat-icon {
      color: $search-input-bg-color;
    }

    input {
      color: $search-input-bg-color;
      background: $search-input-font-color;
      margin-left: 4px;
      height: 2.5rem;
      border: none;
      outline: none;

      &::placeholder {
        color: $search-input-bg-color;
      }
    }
  }

}

@media screen and (max-width: 959px) {
  .search-input {
    width: 100%;
    height: auto;
    margin: 1rem auto !important;
  }
}

// ui-switch
.switch.switch-medium {
  padding: 4px;
  height: 25px;
  background: $switch-background-off;
  border: $switch-border;

  small {
    top: 4px;
    width: 25px;
    height: 25px;
    box-shadow: none;
    // background: $color-white;
  }

  &.checked {
    color: $switch-color-on;
    background: $switch-background-on;

    small {
      right: 4px;
    }
  }

  .switch-pane {
    span {
      line-height: 25px;
      text-transform: uppercase;
      font-size: $font-size-xs;
      font-weight: 700;
    }

    .switch-label-checked {
      color: $switch-color-on;
    }

    .switch-label-unchecked {
      color: $switch-color-off;
    }
  }

}

.switch.switch-small {
  background: $switch-background-off;
  border: $switch-border;

  &.checked {
    color: $switch-color-on;
    background: $switch-background-on;
  }

  .switch-pane {

    .switch-label-checked {
      color: $switch-color-on;
    }

    .switch-label-unchecked {
      color: $switch-color-off;
    }
  }

}

// interval-selector
.interval-selector {
  background: $selector-bg-color;
  border: 1px solid $selector-divider-color;
  border-radius: $radius-2;
  padding: 0 1rem;

  &.alternative-color {
    background: $selector-bg-color-alternative;
  }

  .box {
    border-right: 2px solid $selector-divider-color;
    margin-right: 1rem;
    padding-right: 0.5rem;
  }

  .box:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }

  .icon {
    margin-right: 0.75rem;
    color: $color-primary;
    fill: $color-primary;
  }

  label {
    color: $selector-label-font-color !important;
  }

  ::ng-deep {

    .mat-form-field-label {
      color: $selector-label-font-color !important;
    }

    .mat-select-arrow {
      color: $selector-value-font-color !important;
    }
  }

  span {
    color: $selector-value-font-color;
  }

  input.mat-input-element:disabled {
    color: $selector-value-font-color;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field {
    line-height: 1.75;
  }

  .mat-form-field-flex {
    background: transparent;
  }

  .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(-13%);
  }

  .mat-form-field.mat-focused .mat-select-arrow {
    transform: rotateZ(135deg);
  }

  .mat-select-arrow {
    width: 7px;
    height: 7px;
    border-left: 0px solid transparent;
    border-right: 2px solid;
    border-top: 2px solid;
    transform: rotateZ(135deg);
    margin: 0 4px;
    color: $selector-value-font-color !important;
  }

}

@media screen and (max-width: 959px) {

  .interval-selector {
    padding: 1rem 1rem 0;
    margin-top: 1rem;

    .box {
      border-right: none;
      margin-right: inherit;
      padding-right: 0;
    }

    .box,
    .mat-form-field {
      width: 100%;
    }
  }

  ::ng-deep {
    .mat-form-field-appearance-fill .mat-form-field-flex {
      padding: .75rem .75rem 0 0 !important
    }
  }
}

@media screen and (max-width: 1440px) {
  .interval-selector {
    padding: 1rem 1rem 0;
    margin-top: 1rem;
  }
}

// aggregation-selector
.aggregation-selector {
  background: $color-white;
  border-radius: $radius-2;
  padding: 0 1rem;

  .box {
    border-right: 2px solid #F6F8F8;
    margin-right: 1rem;
    padding-right: 0.5rem;
  }

  .box:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }

  .icon {
    // margin-right: 0.75rem;
    color: $color-primary;
    fill: $color-primary;
  }

  label {
    color: #BFCACF;
  }

  span {
    color: #777E88;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field {
    line-height: 1.75;
  }

  .mat-form-field-flex {
    background: transparent;
  }
}

// select-box
.select-box {
  background: $selector-bg-color;
  border-radius: $radius-2;
  padding: 0 1rem;

  .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(-13%);
  }

  .mat-form-field.mat-focused .mat-select-arrow {
    transform: rotateZ(135deg);
  }

  .mat-select-arrow {
    width: 7px;
    height: 7px;
    border-left: 0px solid transparent;
    border-right: 2px solid;
    border-top: 2px solid;
    transform: rotateZ(135deg);
    margin: 0 4px;
    color: $selector-value-font-color !important;
  }

  &.compact {
    padding: 0;

    .mat-form-field-flex {
      padding: 0 0.5rem;
    }

    .mat-form-field-infix {
      padding: 0;
      border: none;
      line-height: inherit;
      width: 125px;
    }

    .mat-select-arrow-wrapper {
      transform: none;
    }

    .mat-form-field-disabled {
      .mat-form-field-flex {
        background: none;
      }

      span {
        color: #cecece !important;
      }
    }
  }

  .box {
    border-right: 2px solid $selector-divider-color;
    margin-right: 1rem;
    padding-right: 0.5rem;
  }

  .box:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }

  .icon {
    margin-right: 0.75rem;
    color: $color-primary;
    fill: $color-primary;
  }

  label {
    color: $selector-label-font-color !important;
  }

  span {
    color: $selector-value-font-color;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field {
    line-height: 1.75;
  }

  .mat-form-field-flex {
    background: transparent;
  }

  ::ng-deep {

    .mat-form-field-label {
      color: $selector-label-font-color !important;
    }

    .mat-select-arrow {
      color: $selector-value-font-color !important;
    }
  }

  input.mat-input-element:disabled {
    color: $selector-value-font-color;
  }

}

@media screen and (max-width: 959px) {
  .select-box {
    width: 100%;

    .box {
      width: inherit;
      border-right: none;
      margin-right: 0;
      padding-right: 0;
    }

    .mat-form-field {
      width: 100%;
    }
  }

}

.mat-menu-panel {
  background: $mat-menu-bg-color;

  .mat-menu-item {
    color: $mat-menu-font-color;

    .mat-icon-no-color,
    .mat-menu-submenu-icon {
      color: $mat-menu-icon-color;
    }
  }
}

.mat-select-panel {

  background: $selector-bg-color;

  &:not([class*=mat-elevation-z]) {
    box-shadow: 0 0 0.75rem 0.25rem rgba(0, 0, 0, 0.1);
    border-radius: $radius-2;
  }

  .mat-option {
    background: $selector-bg-color;
    color: $selector-value-font-color;

    &:hover:not(.mat-option-disabled) {
      background: transparentize($color: $selector-bg-color, $amount: 0.04);
    }

    &.mat-option-disabled {
      background: $selector-bg-color-disabled;
      color: $selector-value-font-color-disabled;
    }

    &.mat-selected {
      background: $color-primary !important;
      color: $selector-selected-font-color !important;
    }
  }

}

// ff-dialog
.ff-dialog {
  mat-dialog-container {
    display: block;
    padding: 0px;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    min-width: 260px;
    height: 100%;
    min-height: 20%;
    max-height: inherit;
    background: $dialog-body-bg-color;
    color: $dialog-body-font-color;
  }

  mat-dialog-content {
    display: block;
    margin: 0;
    padding: 1rem 1.5rem 1.5rem;
    min-height: 0vh;
    max-height: calc(80vh - 56px);
    overflow: auto;
    background: $dialog-body-bg-color;
    color: $dialog-body-font-color;
    -webkit-overflow-scrolling: touch;

    &.bigger {
      max-height: calc(90vh - 56px);
    }
  }

  mat-dialog-actions {
    padding: 8px 0;
    display: flex;
    flex-wrap: wrap;
    min-height: 52px;
    align-items: center;
    box-sizing: content-box;
    margin-bottom: 0;
  }

  .mat-dialog-title {
    background: $dialog-title-background;
    color: $dialog-title-color;
    margin-bottom: 0;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  }

  button.close {
    background: transparent;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    border: 0;
    border-radius: $radius-3;
    height: $button-s-height;

    .icon {
      width: 1.25rem;
      height: 1.5rem;
      margin-right: 0.25rem;
    }

    .icon,
    .icon svg {
      color: $dialog-title-color;
      fill: $dialog-title-color;
    }

    span {
      font-size: $font-button-m;
      line-height: $line-height-button-m;
      color: $dialog-title-color;
      margin-left: 0.5rem;
    }
  }
}

// datapoint-dialog
.datapoint-dialog {
  mat-dialog-container {
    display: block;
    padding: 0px;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    min-width: 260px;
    height: 100%;
    min-height: 20%;
    max-height: inherit;
    background: $dialog-body-bg-color;
  }

  mat-dialog-content {
    display: grid;
    margin: 0;
    padding: 0;
    min-height: 50vh;
    max-height: 80vh;
    overflow: auto;
    background: $dialog-body-bg-color;
    color: $dialog-body-font-color;
    -webkit-overflow-scrolling: touch;

    div.buttons-container {
      max-height: 40px !important;
      margin: 12px;

      button {
        border-radius: $radius-3 !important;
        height: $button-s-height !important;
        min-height: $button-s-height;
        background: $button-background-1;

        span {
          font-size: $font-button-m;
          line-height: $line-height-button-m;
        }

        &.selected {
          background: $filter-button-background;

          span {
            color: $filter-button-color;
          }
        }

      }
    }
  }

  .mat-dialog-title {
    background: $dialog-title-background;
    color: $dialog-title-color;
    margin: 0;
    padding: 8px 20px;
  }

  button.close {
    background: transparent;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    border: 0;
    border-radius: $radius-3;
    height: $button-s-height;

    .icon {
      width: 1.25rem;
      height: 1.5rem;
      margin-right: 0.25rem;
    }

    .icon,
    .icon svg {
      color: $dialog-title-color;
      fill: $dialog-title-color;
    }

    span {
      font-size: $font-button-m;
      line-height: $line-height-button-m;
      color: $dialog-title-color;
      margin-left: 0.5rem;
    }
  }
}

// checkbox
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background: $color-primary;
}

// ngx-slider
.ngx-slider {

  max-width: 300px;

  &.no-max-width {
    max-width: 100% !important;
  }

  .ngx-slider-active {
    outline: none;
  }

  .ngx-slider-bar {
    height: 4px !important;
    border-radius: $radius-2 !important;
    background: $slider-bar-backgorund !important;
  }

  .ngx-slider-pointer {
    width: 20px !important;
    height: 20px !important;
    top: -8px !important;
    background: $color-primary !important;
    border-radius: $radius-1 !important;
  }

  .ngx-slider-pointer:after {
    width: 12px !important;
    height: 12px !important;
    top: 4px !important;
    left: 4px !important;
    border-radius: 50% !important;
    background: $color-white !important;
  }

  .ngx-slider-selection {
    background: $color-primary !important;
  }
}

.ngx-slider-container {
  margin-left: 1.5rem;
}

// ff-widgets
.ff-widget-container {
  padding: 8px 0 8px 8px;
}

.ff-widget-box {
  background: $card-box2-background;
}

.ff-widget-container .ff-widget-box {
  background: $card-box-background;
}

div.ff-input-select {
  background: $input-select-bg-color;
  color: $input-select-font-color;
  border-radius: 5px;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  place-content: center flex-start;
  align-items: center;
  padding: 0 12px;
  width: 90%;
  min-width: 160px;

  span {
    color: $input-select-font-color;
  }

  &.lang-toolbar {
    background: $language-toolbar-background;
    color: $language-toolbar-color;
  }

  &.radius-2 {
    border-radius: $radius-2;
  }

  &.higher {
    min-height: 60px;
  }

  &.larger {
    min-width: 300px;
  }

  &.w100 {
    width: 100% !important;
  }

  &.smaller {
    min-height: 30px;
  }

  &.just-icon {
    width: unset;
    min-width: 0;
  }
}

input.ff-input,
div.ff-input {
  border-radius: 5px;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  place-content: center flex-start;
  align-items: center;
  padding: 0 12px;
  width: 90%;
  min-width: 160px;
  font-size: 1rem;
  background: rgb(0 0 0 / 3%);
  border: 1px solid #DFE4E7;
  color: $ff-input-font-color;

  &.mail {
    border-radius: 15px;
    min-height: 50px;
    border: 1.5px solid #DFE4E7;
  }

  &.radius-2 {
    border-radius: $radius-2;
  }

  &.higher {
    min-height: 60px;
  }

  &.w100 {
    width: 100%;
  }

  &.minHeightSmall {
    width: 30px;
  }

  &.minWidthSmall {
    width: 60px;
  }

}

input.ff-input,
input.ff-input:focus {
  outline: none;
}

input.ff-input:focus {
  box-shadow: 0 0 2pt 0 $color-primary;
}


select.ff-input,
input.ff-input,
textarea.ff-input {
  border-radius: 5px;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  place-content: center flex-start;
  align-items: center;
  padding: 0 12px;
  width: 90%;
  min-width: 160px;
  font-size: 1rem;
  background: rgb(0 0 0 / 3%);
  border: 1px solid #DFE4E7;

  &.err {
    background: rgba(252, 82, 73, .2);
    border: 1px solid rgba(252, 82, 73, .7);
  }

  &.mail {
    border-radius: 15px;
    min-height: 50px;
    border: 1.5px solid #DFE4E7;
  }

  &.number {
    // width: fit-content;
    min-width: 90px;
  }

  &.radius-2 {
    border-radius: $radius-2;
  }

  &.higher {
    min-height: 60px;
  }
}

select.ff-input,
input.ff-input,
select.ff-input:focus,
input.ff-input:focus,
textarea.ff-input,
textarea.ff-input:focus {
  outline: none;
}

select.ff-input:focus,
input.ff-input:focus,
textarea.ff-input:focus {
  box-shadow: 0 0 2pt 0 $color-primary;
}

div.error-input {
  color: $color-red;
  font-weight: 800;
  font-size: 0.7rem;
}

div.transparent-input {
  color: transparent;
  opacity: 0;
  cursor: default;
  font-weight: 800;
  font-size: 0.7rem;
}

textarea.ff-textarea {
  resize: none;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  place-content: center flex-start;
  align-items: center;
  padding: 12px;
  width: 100%;
  min-width: 160px;
  font-size: 1rem;
  background: rgb(0 0 0 / 3%);
  border: 1px solid #DFE4E7;

  &.radius-2 {
    border-radius: $radius-2;
  }

}

textarea.ff-textarea,
textarea.ff-textarea:focus {
  outline: none;
}

textarea.ff-textarea:focus {
  box-shadow: 0 0 2pt 0 $color-primary;
}

.fc * {
  font-family: inherit !important;
}


.fc {

  .fc-button-primary {
    background: $toolbar-background !important;
    color: $toolbar-color !important;
    border: none !important;
  }

  .fc-prev-button {

    .fc-icon {
      font-family: $font-main-family !important;
      font-weight: bold !important;
    }

    ::before {
      content: "";
    }

    ::after {
      content: "<";
    }
  }

  .fc-next-button {

    .fc-icon {
      font-family: $font-main-family !important;
      font-weight: bold !important;
    }

    ::before {
      content: "";
    }

    ::after {
      content: ">";
    }
  }

  .fc-button {
    border-radius: $radius-3 !important;
  }

  .fc-button-group>.fc-button {
    margin: 0 4px;
  }

  table.fc-scrollgrid {
    border-radius: $radius-2;

    thead {
      .fc-scroller {
        overflow: hidden !important;
      }

      td {
        border-radius: $radius-2 $radius-2 0 0;
      }
    }

    // tbody td {
    //   border-radius: 0 0 $radius-2 $radius-2;
    // }
  }

  .fc-daygrid-day-number {
    padding: 2px 12px !important;
    border-radius: 0 0 0 10px !important;
  }

  .fc-daygrid-event {
    border-radius: $radius-3;
  }

  .fc-daygrid-event-dot {
    display: none !important;
  }

  .fc-v-event {
    border: 1px solid #0000001a;
    border-radius: $radius-3;
    box-shadow: none !important;

    .fc-event-title {
      top: calc(50% - 10px);
    }
  }

  .fc-event-title {
    padding: 0 8px !important;
    font-weight: normal !important;
  }

  .fc-popover {
    border-radius: $radius-3;
    border: none !important;

    .fc-popover-header {
      padding: 0.5rem 0.75rem !important;
      background: $dialog-title-background;
      border-radius: $radius-3 $radius-3 0 0;
      color: $dialog-title-color;
    }
  }

  .fc-highlight {
    background: $color-primary-025 !important;
  }
}

.text-center {
  text-align: center;
}

.betweenRows {
  position: relative;
  top: 14px;
}

.betweenRows span {
  font-weight: 800 !important;
  font-size: 20px;
}

.mh100vh-300px {
  min-height: calc(100vh - 300px);
}


.img-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  /*set the size of the lens:*/
  width: 60px;
  height: 60px;
}


.data-not-found {
  background: $card-background;
  height: calc(100% - 12px);
  width: 100%;
  border-radius: $radius-3;

  &.dash {
    background: transparent;
  }

  span {
    font-weight: 500;
    color: $color-secondary;

    &.add-opacity {
      opacity: 0.48;
    }
  }
}

div.not-found {
  background: $data-not-found-bg-color;
  height: calc(100% - 32px);
  width: calc(100% - 32px);
  border-radius: $radius-1;
  min-height: 100px;

  &.w100 {
    width: 100%;
  }

  span {
    font-weight: 600;
    color: $data-not-found-font-color;
  }
}

// SIDENAV
.sidenav-container {
  width: $sidenav-width-collapsed;
  background: $sidenav-background;

  .sidenav-close {
    color: $header-close-color;
  }

  .logo {
    padding: $header-logo-padding;
    background: $header-logo-background;

    img.sml {
      max-width: $header-logo-width;
      max-height: $header-logo-height;
    }
  }

  .list {

    button {
      height: $button-l-height;
      border-radius: $radius-2;
      font-size: $font-size-l;
      line-height: $line-height-button-m;
      color: $sidenav-color;
      background: $sidenav-background;

      .border {
        height: $sidenav-button-border-height;
        width: $sidenav-button-border-width;
        border-radius: $radius-1;
      }

      .icon,
      .icon svg {
        color: $sidenav-color-icon !important;
        fill: $sidenav-color-icon !important;
      }

    }

    button.selected,
    button:hover {
      background: $sidenav-background-selected;
      color: $sidenav-color-selected;

      .border {
        background: $sidenav-border-color-selected;
      }

      .icon,
      .icon svg {
        color: $sidenav-color-icon-selected !important;
        fill: $sidenav-color-icon-selected !important;
      }
    }

    button:disabled {
      background: $sidenav-background-disabled;
      color: $sidenav-color-disabled;

      .border {
        background: $sidenav-border-color-disabled;
      }

      .icon,
      .icon svg {
        color: $sidenav-color-icon-disabled !important;
        fill: $sidenav-color-icon-disabled !important;
      }
    }

  }

  .footer {
    .card {
      border-radius: $radius-2;
      background: $sidenav-footer-voice-background;

      .label {
        color: $sidenav-footer-voice-label-color;
        font-size: $font-size-xs;
      }

      .value {
        color: $sidenav-footer-voice-value-color;
      }

      .icon {
        color: $sidenav-footer-voice-icon-color;
        fill: $sidenav-footer-voice-icon-color;
      }

    }
  }

  &.expanded {
    width: $sidenav-width;

    .logo {
      padding: $header-logo-padding;
      background: $header-logo-background;

      img.big {
        max-width: $header-logo-width;
        max-height: $header-logo-height;
      }
    }

  }

  #toggle-sidenav-column {
    background: $sidenav-arrow-background;

    .mat-icon {
      fill: $sidenav-arrow-color;
      color: $sidenav-arrow-color;
    }
  }

}

// TOOLBAR
mat-toolbar.ff-toolbar {
  width: 100%;
  height: $main-toolbar-height;
  padding: 0 20px 0 12px;
  background: $toolbar-background;

  .back-button {
    background: none;
    color: $toolbar-color;
  }

  .sidenav-open {
    background: none;
    margin: 0 -12px 0 0;
    color: $toolbar-color;
  }

  .logout-button {
    background: none;
    color: $toolbar-color;
    margin: 0 -12px 0 0;
  }

  .border {
    height: 40px;
    width: 5px;
    background: $color-primary;
    border-radius: $radius-1;
    margin: 0 8px 0 16px;
    display: $main-toolbar-pipe-border-display;
  }

  .breadcrumb {
    margin-left: 12px;
    max-width: calc(100% - 120px);

    .voice {
      line-height: $font-size-title;
    }

    .voice:first-child span {
      margin-left: 0;
      color: $toolbar-color;
      font-weight: bold;
      font-size: $font-size-title;
      font-family: $font-main-family-toolbar-first;
    }

    .voice span {
      margin-left: 8px;
      color: $toolbar-color-2;
      // text-transform: capitalize;
      font-size: $font-size-s;
    }
  }

  .calendar {
    margin: 0 8px;
    // color: $toolbar-color;
    font-size: $font-size-s;
  }
}

.toolbar.flag {
  width: 20px;
  height: 20px;
  border-radius: $radius-0;
  border: 2px solid $sidenav-color-icon-selected;
}

@media screen and (max-width: 959px) {
  mat-toolbar {
    .breadcrumb {
      margin-left: .9rem;

      .voice span {
        font-size: $font-size-s;
        color: $toolbar-color;
      }

      .voice:first-child span {
        font-weight: normal;
        font-size: $font-size-s;
      }
    }
  }
}

// TAB BUTTON
button.tab-button {
  padding: 0 0.75rem;
  margin: $tab-button-margin;
  background: $tab-button-background;
  cursor: pointer;
  outline: none;
  box-shadow: $tab-button-inset-shadow;
  opacity: $tab-button-opacity;
  border-radius: $radius-3;
  height: $button-s-height;
  padding: $tab-button-padding;
  border: 0;

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .icon,
  .icon svg {
    color: $tab-button-color;
    fill: $tab-button-color;
  }

  span {
    display: none;
    font-size: $font-button-m;
    line-height: $line-height-button-m;
    color: $tab-button-color;
    margin-left: 0.5rem;
    font-weight: 600;
  }

  &.selected {
    background: $tab-button-background-selected;
    box-shadow: $tab-button-shadow-selected;
    border-bottom: $tab-border-bottom-selected;
    opacity: $tab-button-opacity-selected;
    // font-weight: 600;

    .icon,
    .icon svg {
      color: $tab-button-color-icon-selected;
      fill: $tab-button-color-icon-selected;
      margin-right: 0.25rem;
    }

    span {
      display: inline;
      color: $tab-button-color-selected;
    }
  }

  &:disabled {
    cursor: default;
    background: $tab-button-background-disabled;
    color: $tab-button-color-disabled;

    .icon,
    .icon svg {
      color: $tab-button-color-icon-disabled;
      fill: $tab-button-color-icon-disabled;
    }
  }
}

@media screen and (max-width: 1440px) {

  button.tab-button {
    margin: 2px;

    &.icon {
      margin-right: 0;
    }
  }

}

// REMOTE MONITORING

div.last-update {
  border-radius: $radius-3;
  padding: 4px 12px;

  .icon {
    margin-right: 8px;
  }
}

// SYNOPTIC

div.datapoint {
  background: $datapoint-background;
  border-radius: $radius-2;
  color: $datapoint-color;

  &.linkToPreviousSynoptic {
    background: $datapoint-link-background;
    color: $datapoint-link-color;
  }

  .datapoint-table {
    font-size: $font-size-remote-monitoring-header;
  }
}

span.synoptic {

  &.title {
    font-size: $font-size-remote-monitoring-table;
    color: $datapoint-color;
  }

  &.value {
    font-size: $font-size-remote-monitoring-value;
    color: $datapoint-value-color;
  }

  &.value-table {
    font-size: $font-size-remote-monitoring-table;
    color: $datapoint-value-color;
  }

  &.mobile-table-title {
    font-size: $font-size-l;
    color: $datapoint-background;
  }
}

// // // // // // // // // 
// WIDGETS
// // // // // // // // // 

// WIDGET-CARD
.ff-widget-box {
  border-radius: $radius-2;
}

// VALUE WIDGET
.widget-value {

  &.compressed {
    border-radius: $radius-3;
  }

  &.label {
    border-radius: $radius-3;

    &.boxed {
      background: $main-background;
      padding: $font-size;
    }
  }

  &.title {
    color: $widget-value-title-font-color;
  }

  &.box2 {
    border-radius: $radius-2;

    &.dark-contrast {
      background: $card-box-background;
      box-shadow: $card-shadow-selected;
      color: $card-text-color;

      .icon-box {
        background: $card-background;
      }
    }

    .icon-box {
      border-radius: $radius-2;
    }

    .title-box {
      font-size: $font-size-xs;
    }

    .value {
      font-size: $font-size-xl;
    }

  }
}

// GAUGE WIDGET
.widget-gauge {

  &.circle-main.gauge-rotation,
  &.circle-back.gauge-rotation,
  &.background.gauge-rotation {
    transform: $gauge-rotation;
  }

  &.circle-back {
    stroke: $gauge-stroke-circle-back;
  }

  &.background {
    fill: $gauge-background;
  }

  &.value {
    fill: $gauge-value-color;
    font-size: $gauge-value-size;
    font-weight: $gauge-value-weight;
  }

  &.label {
    fill: $gauge-label-color;
    font-weight: $gauge-label-weight;
    font-size: $font-size-s;
    color: $card-text-color-2;
  }

  &.infovalue {
    font-size: $font-size-s;
  }

  &.title {
    color: $widget-value-title-font-color;
    font-size: $font-size-xl;
    font-weight: 800;
    text-overflow: ellipsis;
    width: 100%;
    overflow-x: clip;
  }

}

// TABLE WIDGET
.complete-card-frame {
  border-radius: $radius-2;
  background: $color-white;
}

table.widget-table-sortable {
  font-size: $font-size-s;

  tbody>.clickable:hover {
    background: $line-background-hover;
  }

  tr {
    color: $line-header-color;
    background: $line-background;
    border-bottom: 1px solid $line-color-border-header;

    &.selectable:hover {
      background: $line-background-hover !important;
    }

    &.selected {
      background: $line-background-selected !important;
      color: $line-color-selected !important;

      td {
        background: $line-background-selected !important;
        color: $line-color-selected !important;
      }
    }

    &.critical {
      background: $line-background-critical !important;
      color: $line-color-critical !important;

      td {
        background: $line-background-critical !important;
        color: $line-color-critical !important;
      }
    }

    &.subcritical {
      background: $line-background-subcritical !important;
      color: $line-color-subcritical !important;

      td {
        background: $line-background-subcritical !important;
        color: $line-color-subcritical !important;
      }
    }

    &.third-color {
      background: $line-background-third-color !important;
      color: $line-color-third-color !important;

      td {
        background: $line-background-third-color !important;
        color: $line-color-third-color !important;
      }
    }
  }

  th {
    color: $line-header-color;
    background: $line-header-bg-color;
  }

  td {
    color: $line-body-color;
    background: $line-background;
  }

}

.widget-table-sortable {

  &.title {
    color: $widget-value-title-font-color;
  }

  &.subtitle {
    color: $widget-value-title-font-color;
  }

  &.vertical-divider {
    border: 1px solid $line-color-border-header;
  }

  &.mobile-card {

    span.label {
      font-size: $font-size-xs;

      &.widget {
        font-size: $font-size;
      }
    }

    .info-box {

      .value.widget {
        font-size: $font-size-l;
      }

    }

  }

}

button.sortArrow {
  background: $filter-button-background;
  border-radius: $radius-2;

  .icon,
  .icon svg {
    color: $filter-button-color-icon;
    fill: $filter-button-color-icon;
  }
}

// OPTIONS

.option-box {
  border-radius: $radius-2;

  .title {
    color: $widget-value-title-font-color;
    font-size: $font-size-xxl;
  }

  .title-image {
    background: $tab-button-background-selected;
    border-radius: $radius-0;

    .icon {
      color: $tab-button-color-icon-selected;
      fill: $tab-button-color-icon-selected;
    }
  }

  button {
    border-radius: $radius-3;
    height: $button-s-height;
    min-height: $button-s-height;
    background: $sidenav-background-selected;
    color: $sidenav-color-selected;

    .check {
      border-radius: $radius-0;

      .icon {
        color: $sidenav-background-selected;
      }

      &.current {
        background: $sidenav-color-icon-selected;
      }
    }

    .flag {

      img {
        border-radius: $radius-0;
        border: 2px solid $sidenav-color-icon-selected;
      }
    }

  }

}

// // // // // // // // //
// HOMEPAGE CARD
// // // // // // // // //

span.suggestion {
  color: $homepage-suggestion-span-font-color;
}

mat-card.homepage-card {
  margin: $card-margin 0 $card-margin 0;
  padding: $card-padding;
  background: $card-background;
  border-radius: $radius-2;
  border-width: $card-border-width;
  border-color: $card-border-color;
  color: $card-text-color;

  &.selected {
    background: $card-background-selected;
    box-shadow: $card-shadow-selected;
    border-color: $card-border-color-selected;
  }

  &.card-style-0 {

    .header {

      .title {
        color: $card-title-color;
        font-size: $card-title-font-size;
        margin: $card-title-margin;

        .subtitle {
          color: $card-text-color-2;
          font-size: $font-size-xl;
        }
      }

      .status {
        font-size: $font-size-s;
        border-radius: $radius-3;

        &.run {
          background: $card-machine-status-1-background;
          color: $card-machine-status-1-color;
        }

        &.never {
          background: $card-machine-status-2-background;
          color: $card-machine-status-2-color;
        }

      }

    }

    .box-container {

      &.image {
        background: $card-image-background;
        border-radius: $radius-2;
      }
    }

    p.title,
    div.title-medium {
      font-size: $font-size-l;

      &.underline {

        span {
          border-bottom: 3px solid $color-tertiary;
        }
      }

      span.subtitle {
        font-size: $font-size-s;
      }

    }

    .box {
      border-radius: $radius-2;
      background: $card-box-background;

      &.highlight {
        border: 2px solid $card-border-color-selected;
        box-shadow: $card-background-selected;
      }

      .box {
        border-radius: $radius-2;
        background: $card-box2-background;
      }

      .icon-box {
        border-radius: $radius-2;

        &.main-color {
          background: $card-box2-icon-1-background;
        }

        .icon {
          color: $card-box2-icon-1;
        }

      }

    }

    .image {

      img {
        border-radius: $radius-2;
      }

    }

    .image-location {

      .border {
        border-radius: $radius-1;
        background: $color-tertiary;
      }

    }

    .production {

      .box2 {
        background: $card-box2-icon-2-background;
      }

      .icon-box {
        background: $card-box2-icon-3-background;
      }

    }

    .aggrTable {

      background: $card-background;
      border-radius: $radius-2;

      table {

        mat-icon {
          font-size: $font-size;
        }

        tr th {
          font-size: $font-size-s;
          border-bottom: 1px solid $line-color-border-header;
        }

        tr,
        tr td {
          font-size: $font-size-s;
          border-bottom: 1px solid $line-color-border-header;
        }

      }

    }
  }

  // //////////////////////////////////////////////////////////////////////
  // style 2
  &.card-style-2 {

    .header {

      .title {
        color: $card-title-color;
        font-size: $font-size-xl;
      }

      .status {
        font-size: $font-size-s;
        border-radius: $radius-3;

        &.run {
          background: $card-machine-status-1-background;
          color: $card-machine-status-1-color;
        }

        &.never {
          background: $card-machine-status-2-background;
          color: $card-machine-status-2-color;
        }

      }

    }

    .box {
      border-radius: $radius-2;
      background: $card-box-background;

      p {

        &.title span:nth-child(1) {
          font-size: $font-size-l;
        }

        &.title span:nth-child(2) {
          color: $card-text-color-2;
        }

        &.job {
          font-size: $font-size-s;
        }

      }

    }

    .info-image {

      .icon-box {
        background: $card-box2-icon-1-background;
      }

    }

    .box2 {
      border-radius: $radius-2;

      .icon-box {
        border-radius: $radius-2;

        .icon {
          color: $card-box2-icon-1;
        }
      }

      .title {
        font-size: $font-size-xs;
        color: $card-text-color-2;
      }

    }

    .image {

      img {
        border-radius: $radius-2;
      }

    }

    .production {

      .box2 {
        background: $card-box2-icon-2-background;
      }

      .icon-box {
        background: $card-box2-icon-3-background;
      }

    }

  }

  // //////////////////////////////////////////////////////////////////////
  // style 3
  &.card-style-3 {

    .header {

      .title {
        color: $card-title-color;
        font-size: $font-size-xl;
      }

      .status {

        &.run {
          background: $card-machine-status-1-background;
          color: $card-machine-status-1-color;
        }

        &.never {
          background: $card-machine-status-2-background;
          color: $card-machine-status-2-color;
        }

      }

    }

    .box {
      border-radius: $radius-2;
      background: $card-box-background;

      p {

        &.title span:nth-child(1) {
          font-size: $font-size-l;
        }

        &.title span:nth-child(2) {
          color: $card-text-color-2;
        }

        &.job {
          font-size: $font-size-s;
        }

      }

    }

    .box2 {
      border-radius: $radius-2;
      background: $card-box2-background;

      .icon-box {
        border-radius: $radius-2;

        &.main-color {
          background: $card-box2-icon-1-background;
        }

        .icon {
          color: $card-box2-icon-1;
        }

      }

      .title {
        font-size: $font-size-xs;
        color: $card-text-color-2;
      }

    }

    .image {

      img {
        border-radius: $radius-2;
      }

    }

    .production {

      .box2 {
        background: $card-box2-icon-2-background;
      }

      .icon-box {
        background: $card-box2-icon-3-background;
      }

    }

  }

  // //////////////////////////////////////////////////////////////////////
  // style 4
  // style 5
  &.card-style-4,
  &.card-style-5,
  &.card-style-6 {

    .header {

      .title {
        color: $card-title-color;
        font-size: $font-size-xl;
      }

      .status {
        font-size: $font-size-s;
        border-radius: $radius-3;

        &.run {
          background: $card-machine-status-1-background;
          color: $card-machine-status-1-color;
        }

        &.never {
          background: $card-machine-status-2-background;
          color: $card-machine-status-2-color;
        }

      }

    }

    .box {
      border-radius: $radius-2;
      background: $card-box-background;

      p {

        &.title span:nth-child(1) {
          font-size: $font-size-l;
        }

        &.title span:nth-child(2) {
          color: $card-text-color-2;
        }

        &.job {
          font-size: $font-size-s;
        }

      }

    }

    .box2 {
      border-radius: $radius-2;
      background: $card-box2-background;

      .icon-box {
        border-radius: $radius-2;

        &.main-color {
          background: $card-box2-icon-1-background;
        }

        .icon {
          color: $card-box2-icon-1;
        }

      }

      .title {
        font-size: $font-size-xs;
        color: $card-text-color-2;
      }

    }

    .image {

      img {
        border-radius: $radius-2;
      }

    }

    .production {

      .box2 {
        background: $card-box2-icon-2-background;
      }

      .icon-box {
        background: $card-box2-icon-3-background;
      }

    }

  }

}

.homepage-card.sub-machine-title {
  color: $card-title-color;
  font-size: $font-size-xl;
}

// // // // // // // // //
// HOMEPAGE LIST
// // // // // // // // //

mat-card.homepage-list {
  margin: 0 auto 0.75rem auto;
  overflow-x: auto;
  overflow-y: hidden;
  background: $card-background;
  box-shadow: $card-shadow-selected;
  border-radius: $radius-2;

  .table-title {
    font-size: $font-size-xxl;
    font-weight: 800;
  }

  table {
    width: 100%;
    background: transparent;
    border-collapse: collapse;

    tr th {
      text-align: initial;
      padding: 0.5rem 0 0.5rem 0.25rem;
      color: $line-header-color;
      background: $line-header-bg-color;
      border-bottom: 1px solid $line-color-border-header;
    }

    tr,
    tr td {
      padding: 8px;
      color: $line-color;
      background: $line-background;
      border-bottom: 1px solid $line-color-border-header;
    }

    tr.selected,
    tr.selected td {
      color: $line-color-selected;
      background: $line-background-selected;
    }
  }

}

// // // // // // // // //
// HOMEPAGE MAP
// // // // // // // // //

agm-map {
  width: 100%;
  height: calc(100vh - #{$main-toolbar-height} - #{$tabs-height} - 200px);

  .agm-map-container-inner {
    border-radius: $radius-1;
  }

}

.gm-ui-hover-effect {
  top: 4px !important;
  right: 4px !important;
  width: 28px !important;
  height: 28px !important;
}

.gm-ui-hover-effect>span {
  width: 20px !important;
  height: 20px !important;
  margin: 4px !important;
  background: $map-card-font-color;
  // background: $dialog-title-color;
}

.ff-agm-info-window-content {
  padding: 0.5rem;

  .image-container {
    padding-right: 1rem;
    margin-top: 40px;

    img {
      max-width: 100px;
      max-height: 100px;
      // background: $map-card-image-background;
    }
  }

  .info-container {

    .title {
      margin: 0;
      color: $card-title-color;
      font-size: $font-size-xl;
      font-weight: 600;
    }

    .content {
      min-width: 200px;
      margin: 0.25rem auto;
      color: $card-text-color-2;

      .value {
        font-weight: 600;
        overflow-wrap: anywhere;
        word-break: break-word;
      }

    }

  }

}

.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background: $map-card-background !important;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
  background: $map-card-background;
}

@media screen and (max-width: 960px) {
  .ff-agm-info-window-content .image-container {
    padding: 0;
  }
}

// switch-container

div.switch-container {
  background: $color-white;
  color: $widget-value-span-font-color;
  border-radius: $radius-2;
  min-height: 60px;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;

  &.small {
    min-height: 32px;
    padding: 0 4px;
  }
}

@media screen and (max-width: 959px) {

  div.switch-container {
    display: none;
    height: 60px;
    margin-left: 0px;
    margin-top: 8px;
    width: 100%;
  }
}

// // // // // // // // //
// BOT
// // // // // // // // //
.chatbot {

  .chat-container {
    border: 1px solid #e6e6e6
  }

  .chat-bar {
    background: $color-primary;
    color: $color-white;

    .chat-with {
      font-size: $font-size-xxl;
    }
  }

  .chat {
    background: #F2F5F8;
    color: #434651;

    .chat-header {
      border-bottom: 2px solid $color-white;
    }

    .chat-history {
      border-bottom: 1px solid $color-white;

      .message-data-time {
        color: #a8aab1;
      }

      .message {
        font-size: $font-size-l;
      }

      .other-message {
        background: $color-white;

        &:after {
          border-bottom-color: #777E88;
          left: 93%;
        }

      }

      .my-message:not(button) {
        background: $color-white;
      }

    }
  }

  .bellnumbers {
    background: red;
    color: $color-white;
  }
}


// // // // // // // // //
// DASHBOARD DIALOG
// // // // // // // // //

.dashboard-dialog.widget-card {
  background: $card-background;
  border-radius: $radius-2;
}

// // // // // // // // //
// SHIFT TEMPLATES
// // // // // // // // //

div.shift-template {
  background: $color-white;
  border-radius: $radius-1;
  padding: 16px;
  margin-bottom: 8px;
  width: calc(100% - 32px);
}

span.shift-template-title {
  font-weight: 800;
  font-size: $font-size-xl;
}

// SPAN TITLE
span.title {
  font-size: $font-size-xl;
  font-weight: 800;
}

.warning-shift-template-name {
  margin-top: 8px;
  padding: 8px;
  border-radius: $radius-3;
}

// // // // // // // // //
// STANDARD TABLE
// // // // // // // // //

table.standard-table {
  width: 100%;
  background: transparent;
  border-collapse: collapse;

  tr th {
    text-align: initial;
    padding: 8px;
    color: $line-header-color;
    background: $line-header-bg-color;
    border-bottom: 1px solid $line-color-border-header;
  }

  tr,
  tr td {
    padding: 8px;
    color: $line-color;
    background: $line-background;
    border-bottom: 1px solid $line-color-border-header;
  }

  tr.selected,
  tr.selected td {
    color: $line-color-selected;
    background: $line-background-selected;
  }

  tr.comparison,
  tr.comparison td {
    opacity: 0.5;
    cursor: not-allowed;
    background: $cycle-selected-disabled;
    pointer-events: none;
  }

}

// // // // // // // // //
// ERROR CARD
// // // // // // // // //
div.error-card {
  word-break: break-word;
  border-radius: 25px;
  font-size: $font-button-l;
  line-height: $line-height-button-l;
  max-width: 450px;
  padding: 16px;

  &.type-0 {
    color: white;
    /* fallback for old browsers */
    background: #ff9966;
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to left, #eb1319, #ed7b42);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to left, #eb1319, #ed7b42);

  }

  &.type-1 {
    color: white;
    background: orange;
  }

  &.type-2 {
    // color: white;
    background: #85b5ff;
  }

  .icon-container {

    .icon {
      width: 30px;
      height: 30px;
      padding: 0 16px 0 8px;
      color: white;
      line-height: $line-height-button-l;
      vertical-align: middle;
    }
  }

  .message-container {

    margin-top: 8px;

    span {
      margin: 0;
      padding: 8px 0;

      &.main-message {
        line-height: $font-size-main-error;
        font-size: $font-size-main-error;
        font-weight: 600;
      }
    }
  }
}

// // // // // // // // //
// LOADING CARD
// // // // // // // // //

mat-card.loading-card {
  border-radius: $radius-1;
  font-size: $font-button-l;
  line-height: $font-button-l;
  max-width: 450px;
  color: $color-primary;
  background: $loading-card-bg-color;

  &.padding0 {
    padding: 0 !important;
  }

  .icon-container {

    .icon {
      width: 2rem;
      height: 2rem;
      padding: 0 0.5rem;
      color: $color-primary;
      line-height: $line-height-button-l;
      vertical-align: middle;
    }
  }

  .message-container {
    padding: 0.5rem;

    p {
      margin: 0;
      padding: 0.5rem 0;
    }
  }
}

// // // // // // // // //
// REQUIRED
// // // // // // // // //
div.required {
  font-weight: 600;
  color: red;
  padding: 4px 0;
  font-size: $font-size-xs;
}

span.error {
  font-weight: 600;
  color: red;
  padding: 4px 0;
  font-size: $font-size-xl;
}


// // // // // // // // //
// // // // // // // // //
// // // // // // // // //
// CMMS
// // // // // // // // //
// // // // // // // // //
// // // // // // // // //

mat-dialog-content.cmms-new-component {
  background: $dialog-body-component-detail-bg-color;

  span.headline {
    font-weight: 600;
    font-size: $font-size-xxl;
  }

  div.new-component-container {
    background: $color-white;
    padding: 16px;
    margin-top: 24px;
    border-radius: $radius-3;
    box-shadow: 0px 10px 50px rgb(119 126 136 / 10%) !important;
  }

}


mat-dialog-content.alternative-color {
  background: $dialog-body-component-detail-bg-color;
}


// // // // // // // // // // // // // // // // // //
// ATTACHMENT BUTTON (Import excel, ecc...)
// // // // // // // // // // // // // // // // // //
.attachment-button {
  font-size: $font-size;
  font-weight: 700;
  margin: .5vh 0 1vh 0;

  .tip {
    background: $color-primary;
    padding: 1rem;
    color: $color-white;
    border-radius: $radius-3 0 0 $radius-3;
  }

  .label {
    padding: 1rem;
    background: $button-background-1;
    color: $button-color-1;
    border-radius: 0 $radius-3 $radius-3 0;
  }
}

// // // // //
// RBA
// // // // //
div.single-event-container {
  background: $rba-card-bg-color;
  border-radius: $radius-2;
  padding: 12px;
  margin-bottom: 12px;
  border: $rba-alert-border;
  max-width: calc(50% - 32px);

  &.admin-alert {
    border: $rba-admin-alert-border;
    box-shadow: $rba-admin-alert-box-shadow;
  }

  &.three {
    margin-bottom: 0;
    max-width: calc(33.3% - 32px);
  }
}

mat-card.rba-container {
  margin: 8px;
  border-radius: 30px;
  // width: 100%;
  height: fit-content;
  box-shadow: 0px 10px 50px rgb(119 126 136 / 10%) !important;

  div.title {
    width: 100%;

    span {
      font-size: $font-size-supreme;
      font-weight: 800;

      &.number {
        color: #A8B6BD;
      }
    }
  }
}

mat-card.custom-mail-container {
  border-radius: 12px;
  box-shadow: 0px 10px 50px rgb(119 126 136 / 10%) !important;

  div.title {
    width: 100%;

    span {
      font-size: $font-size-supreme;
      font-weight: 800;

      &.number {
        color: #A8B6BD;
      }
    }
  }

  div.subtitle {
    margin: 12px 0 20px 0;
  }
}

table.rba-table {

  margin-top: 16px;
  width: 100%;
  border-collapse: collapse;

  tr th {
    padding: 0 0 12px 0;
    text-align: initial;
    font-weight: normal;
    font-size: $font-size-s;
    color: $line-header-color;
    border-bottom: 1px solid $line-color-border-header;
  }

  tr,
  tr td {
    color: $line-color;
    font-weight: 700;
    color: $line-body-color;
    font-size: $font-size-s;
    //   background: $line-background;
    border-bottom: 1px solid $line-color-border-header;
  }

  tr:hover {
    background: $line-background-hover;
  }

  tr.actual {
    opacity: 0.5;
    cursor: not-allowed;
    background: $cycle-selected-disabled;
    pointer-events: none;
  }

  tr.selected {
    background: $cycle-selected;
  }

  th:first-of-type,
  td:first-of-type,
  td:first-of-type {
    padding-left: 8px;
  }

  th:not(:first-of-type),
  td:not(:first-of-type),
  td:not(:first-of-type) {
    padding: 12px 0;
  }

}

.mat-card-content {
  font-size: $font-size;

  span.subtitle {
    font-size: $font-size-s;
  }
}

// // // // // //
// LAST UPDATE
// // // // // //
div.last-update {
  &.compressed {
    border-radius: $radius-3;
  }

  &.label {
    border-radius: $radius-2;
  }
}

// // // // // //
// STATUS WIDGET
// // // // // //
.status-label {
  border-radius: $radius-2;
}

// // // // // //
// VERTICAL DIVIDER
// // // // // //
.vertical-divider {
  border: 1px solid $line-color-border-header;
}


// // // // // //
// 40IMPACT
// // // // // //
.card.impact-consumables-settings {
  border-radius: $radius-2;
  background: $card-background;

  .title {
    color: $widget-value-title-font-color;
    font-weight: 800;
    font-size: $font-size-xl;
    margin-left: 6px;
  }

  .content {
    margin-top: 0.75rem;
    color: $widget-value-title-font-color;
  }
}

.suggestion.impact-consumables-settings {
  color: $widget-value-title-font-color;
  font-style: italic;
  font-size: 1.2rem;
  margin-left: 8px;
}

.widget-value-title {
  color: $widget-value-title-font-color;
}

.widget-value.widget-value-title {
  font-weight: 800;
  text-overflow: ellipsis;
  overflow-x: clip;
}

// // // // // //
// CHECKBOX
// // // // // //
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: $color-primary !important;
  // background: #006eff !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: $color-primary !important;
  // background: #006eff !important;
}


// // // // // // // //
// MACHINE SETTINGS
// // // // // // // //

.machine-settings-card {
  border-radius: $radius-2;

  .title {
    font-weight: 800;
    font-size: $font-size-xl;
    margin-left: 6px;
  }

  .content {
    margin-top: 0.75rem;

    input {
      width: calc(100% - 1rem);
      text-align: center;
      padding: 0.5rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

// // // // // // // //
// AQSEPTENCE - PHASES
// // // // // // // //
div.phase-data-title {
  padding: 16px;
  font-weight: 800;
  color: $sidenav-background;
  font-size: $font-size-l;

  &.gca {
    padding: 8px 16px;
    background: $dialog-title-background;
    color: $dialog-title-color;
    font-size: $font-size-l;
  }

  span.select-phase {
    margin-left: 0.5rem;
    color: $card-text-color-2;
    font-size: $font-size-s;
    font-weight: 500;
  }

}

.box-icon-double {
  padding: 0.2rem;
  border-radius: $radius-2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highlight {
  background: $line-background-hover-2;
}

// // // // // // // //
// CYCLE SELECTION
// // // // // // // //
.selectors-container {
  padding: 8px;
  background: $card-box-background;
  border-radius: $radius-3;
}

table.cycle-selection {
  width: 100%;
  border-collapse: collapse;

  tr th {
    padding: 0.5rem 0 0.5rem 0.25rem;
    text-align: initial;
    font-weight: normal;
    font-size: $font-size;
    color: $line-header-color;
    border-bottom: 1px solid $line-color-border-header;
  }

  tr,
  tr td {
    color: $line-color;
    font-weight: 700;
    color: $line-body-color;
    font-size: $font-size-s;
    //   background: $line-background;
    border-bottom: 1px solid $line-color-border-header;
  }

  tr:hover {
    background: $line-background-hover;
  }

  tr.actual {
    opacity: 0.5;
    cursor: not-allowed;
    background: $cycle-selected-disabled;
    pointer-events: none;
  }

  tr.selected {
    background: $cycle-selected;
  }

  tr.comparison {
    opacity: 0.5;
    cursor: not-allowed;
    background: $cycle-selected-disabled;
    pointer-events: none;
  }

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 8px;
  }

  th.mat-header-cell:not(:first-of-type),
  td.mat-cell:not(:first-of-type),
  td.mat-footer-cell:not(:first-of-type) {
    padding: 8px;
  }

}

// // // // // // // //
// PLANNER DIALOG
// // // // // // // //
.planner-dialog {

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 0.25rem 0;

      span {
        margin-left: 1rem;
      }
    }

    li.disabled {
      opacity: 0.5;
    }
  }

  .field {
    margin-top: .5rem;
  }

  .label {
    color: $color-gray;
    font-size: $font-size-s;
  }

  .actions button {
    padding: 0 1rem;
    margin: 0;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    border: 0;
    border-radius: $radius-3;
    height: $button-s-height;

    .icon {
      width: 1.25rem;
      height: 1.5rem;
      margin-right: 0.25rem;
    }

    .icon,
    .icon svg {
      color: $tab-button-color;
      fill: $tab-button-color;
    }

    span {
      font-size: $font-button-m;
      line-height: $line-height-button-m;
      color: $tab-button-color;
      margin-left: 0.5rem;
    }
  }

  ::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: $color-primary;
  }

  .mat-focused .mat-form-field-label {
    /*change color of label*/
    color: $color-primary !important;
  }

  .mat-focused .mat-select-arrow {
    color: $color-primary !important;
  }

  ::ng-deep .mat-form-field-ripple {
    /*change color of underline when focused*/
    background: $color-primary !important;
  }

  @media screen and (max-width: 959px) {
    ul {
      li {
        padding: 0.75rem 0;
      }
    }
  }

}


// // // // // // // //
// AQP
// // // // // // // //

.AQP-label-2 {
  color: $ff-bar-label-2-color;
}

.AQP-bar-background {
  background: $ff-bar-bg-color;
}


// // // // // // // //
// STATE CONTAINER
// // // // // // // //
div.state-container {
  width: calc(100% - 32px);
  padding: 8px 4px;
  border-radius: 5px;
  color: $state-container-font-color;
  text-align: center;
  font-weight: bold;

  &.full-width {
    width: 100% !important;
  }
}

// // // // // // // //
// CYCLE TRACEABILITY
// // // // // // // //

mat-card.cycle-traceability {
  margin: 0 auto;
  background: $card-background;
  border-radius: $radius-2;
  width: 100%;

  .table-in-td td {
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    td.td-icon {
      width: 4rem;
      padding: 0 !important;
      text-align: center;
    }

    .box-icon {
      padding: 0.5rem !important;
      border-radius: $radius-3 !important;
      min-width: 2.5rem !important;
    }

    tr th {
      padding: 0.5rem 0 0.5rem 0.25rem;
      text-align: initial;
      font-weight: normal;
      font-size: $font-size-s;
      color: $line-header-color;
      border-bottom: 1px solid $line-color-border-header;
    }

    tr,
    tr td {
      padding: 0.75rem 0.5rem;
      color: $line-color;
      font-weight: 700;
      color: $line-body-color;
      font-size: $font-size;
      border-bottom: 1px solid $line-color-border;
    }

    tr:hover {
      background: $line-background-hover;
    }

    tr.expand-row {
      height: 0;
      border-bottom: none;

      .table-phase-container {
        background: #f6f8f8;
        margin: 12px 12px 12px 62px;
        width: calc(100% - 78px);
        border-radius: $radius-2;
        padding: 8px 8px 0 8px;
      }

      table {
        background: transparent;

        tr:last-of-type,
        tr:last-of-type td {
          border-bottom: none;
        }
      }
    }

    tr.expand-row>td {
      padding: 0;
      border-bottom: none;
    }

    .collapsed {
      height: '0px';
      min-height: '0';
    }

    .expanded {
      height: 'auto';
    }

    tr.trained {
      background: $line-background-trained;
    }

  }

}


// // // // // // // //
// MACHINE DOCS
// // // // // // // //
.file-row-container {
  padding: 8px;
}

// // // // // // // //
// HIDE PAGINATOR
// // // // // // // //
.hidePaginator {
  position: fixed;
  top: -9999999px;
  left: 0;
}


// // // // // // // //
// PARAMETER TABLE
// // // // // // // //

.scrapBoolean {
  color: $color-secondary;
  width: calc(100% - 2rem);
  line-height: 1.5rem;
  text-align: center;
  font-size: $font-size-xxl;
  margin-top: 0.5rem;
}

.recipe-set-header {
  background: #f44336;
  color: white !important;
  min-width: 120px;
  border-radius: 12px;
  padding: 1rem !important;
  padding-left: 8px !important;
}


// // // // // // // //
// BAR WIDGET
// // // // // // // //
.bar-box2 {
  width: 100%;
  border-radius: $radius-2;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .icon-box {
    border-radius: $radius-2;
    margin-right: 0.75rem;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      color: $card-box2-icon-1;
    }
  }

  .title {
    font-size: $font-size-xs;
    color: $card-text-color-2;
    @include defaultWidgetTitle
  }

  .value {
    // font-size: $font-size-l;
    font-weight: 600;
    overflow-wrap: anywhere;
    word-break: break-word;
  }

}

.bar-container-label {
  border-radius: $radius-3;
  height: 100%;
  width: 100%;
  margin: 4px 0;
  padding: 0 0 0 8px;
}


// // // // // // // //
// MOBILE CARD
// // // // // // // //

mat-card.mobile-card {
  margin: 0 .5rem 1rem;
  background: $card-background;
  border-radius: $radius-2;
  width: 100%;

  .box-icon {
    max-height: 3rem;
  }

  .mobileCardContainer {
    padding: 0 1rem;

    .mobileCardBody {
      margin-bottom: .2rem;

      span {
        line-height: normal;
        color: #777;
        font-size: $font-size-xs;
      }

      p {
        margin: 0;
        padding: 0;
        line-height: normal;
      }
    }
  }

}

// // // // // // // //
// HEALTH BAR WIDGET (HM CONT EXP)
// // // // // // // //
.hm-bar {

  .bar-container {
    position: relative;
    height: 100%;
    width: 52px;
    margin: 8px;
    border-radius: 20px;

    &.bg {
      background: #F6F8F8;
    }
  }

  .bar {
    position: absolute;
    bottom: 0;
    left: 12px;
    width: calc(100% - 24px);
    opacity: 0.8;
  }

  .bar-background {
    position: absolute;
    bottom: 0;
    width: 40px;
    height: 100%;
    width: 100%;
  }

  .bar-label {
    position: absolute;
    left: 0;
    width: 100%;
    font-size: 20px;
    font-weight: 800;
  }
}


// // // // // // // //
// IMAGE WIDGET
// // // // // // // //
img.widget-image {
  max-width: 100%;
  border-radius: $radius-2;

  &.small {
    max-height: 100px !important;
  }
}


// // // // // // // //
// PLOTLY CHART WIDGET
// // // // // // // //
.plotly-chart-title {
  @include defaultWidgetTitle;
  font-size: $font-size-xl;
  color: $widget-value-title-font-color;
}

div.event-flag {
  position: absolute;
  top: 12px;
  z-index: 999;
  border-radius: 15px;
  opacity: 0.9;

  .tile-container {
    border-radius: 15px;
    // box-shadow: 0px 10px 30px rgba(119, 126, 136, 0.15);

    .title {
      width: calc(100% - 32px) !important;
      background: $machine-recorder-events-title-bg-color;
      color: $machine-recorder-events-title-font-color;
      font-weight: 800;
      border-radius: 15px 15px 0 0;
      padding: 8px 16px;
    }

    .content {
      background: $machine-recorder-events-body-bg-color;
      color: $machine-recorder-events-body-font-color;
      border-radius: 0 0 15px 15px;
      font-weight: 500;
      width: calc(100% - 32px) !important;
      padding: 8px 16px;
      box-shadow: 0px 10px 10px rgb(0 0 0 / 15%);

      .w504px {
        max-width: calc(50% - 4px) !important;
      }

      .icon {
        font-weight: 800;
        color: $machine-recorder-events-icon;

        &.small-icon {
          position: absolute;
          font-size: 12px;
          left: 24px;
          bottom: 12px;
          height: 0;
          width: 8px;
        }
      }
    }

  }
}

div.triangular-indicator {
  position: absolute;
  bottom: -8px;
  right: auto;
  left: auto;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 8px solid $machine-recorder-events-body-bg-color;
}

div.circular-indicator {
  position: absolute;
  bottom: -48px;
  // right: auto;
  // left: auto;
  background: #2FD0CB15;
  border-radius: 50px;
  width: 40px;
  height: 40px;

  .mini {
    position: relative;
    bottom: -16px;
    right: -16px;
    background: #2FD0CB;
    border-radius: 50px;
    width: 10px;
    height: 10px;
  }
}


// // // // // // // //
// PROCESS LOG - GCA VARIABLES
// // // // // // // //
div.plot-container {
  width: 100%;
  margin: 12px 0 0 0;
  border-radius: $radius-2;
  padding: 8px;
  background: $color-white;
}

div.phase-data-title-plots {
  padding: 8px 16px;
  font-weight: 800;
  color: $dialog-title-color;
  font-size: $font-size-l;
  border-radius: $radius-2 $radius-2 0 0;
  background: $dialog-title-background;
  font-size: $font-size-l;

  span.select-phase {
    margin-left: 0.5rem;
    color: $card-text-color-2;
    font-size: $font-size-s;
    font-weight: 500;
  }

}

div.variables-plot {
  height: 350px;
  width: 100%;
}

div.hidePlot {
  position: fixed;
  top: 0;
  right: 500000px;
  z-index: 0;
  opacity: 0;
}

// // // // // // // //
// GCA SETTINGS
// // // // // // // //
.calculator-button {
  @include fixedIconContainer(32px);
  padding: 0.25rem;
  margin: 4px;
  border-radius: 5px;
  font-size: 1.5rem;
  border: 1px solid #e8eced;
}

span.rule-title {
  color: #c1c1c1;
  font-weight: 500;
}

div.custom-rule-container {
  margin: 12px 0 0 8px;
  font-size: 1.2rem;
  background: #f6f8f8;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
}

div.error-container {
  margin: 4px 12px;
}

mat-card.gca-settings {
  margin-bottom: 12px;
  background: $card-background;
  border-radius: $radius-2;
  box-shadow: 0px 10px 50px rgb(119 126 136 / 10%) !important;

  .setting-row {
    margin: 16px 8px;
  }

  div {

    &.subtitle {
      width: 100%;
      margin: 12px 8px;
    }

    &.title {
      width: 100%;
      margin: 8px;

      span {
        font-size: $font-size-supreme;
        font-weight: 800;

        &.number {
          color: #A8B6BD;
        }

      }
    }
  }

}


// // // // // // // //
// MAT CARD - DEFAULT
// // // // // // // //
mat-card.standard-mat-card {
  margin: 8px auto 0 auto;
  background: $card-background;
  //   box-shadow: $card-shadow-selected;
  border-radius: $radius-2;
  width: 100%;

}

div.hm-container {
  background: $color-white;
  border-radius: $radius-2;
  padding: 16px;
}

// // // // // // // // // // // // 
// CYCLE EXPLORATION (HM AND NOT)
// // // // // // // // // // // // 
div.toggle-average-values-table {
  position: absolute;
  opacity: 1;
  top: 0;
  right: 0;
  height: 2rem;
  width: 2rem;
  background: $color-primary;
  border-radius: $radius-2;

  .mat-icon {
    font-size: 1.5rem;
    line-height: 1.2;
  }

  &:hover {
    opacity: 0.5;
  }

}

div.average-values-table {
  padding: 16px;
  background: $color-white;
  border-radius: $radius-2;
  display: none;

  &.open {
    display: table-row;
  }

}

div.table-container {
  position: relative;
  max-height: calc(100% - 42px);
  overflow: auto;
}

mat-card.cycle-table-container {
  background: $card-background;
  border-radius: 0;
  // position: absolute;
  margin-top: 8px;
  width: calc(100% - 32px);
  bottom: 0;
  left: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

// // // // // // // // // // // // 
// HM CARD
// // // // // // // // // // // // 
div.hm-container-card {
  margin: $hm-card-margin 0 $hm-card-margin 0;
  padding: $hm-card-padding;
  border-radius: $radius-2;
  border-style: solid;
  border-width: $hm-card-border-width;
  border-color: $hm-card-border-color;
  color: $hm-card-text-color;
  background: $hm-card-background;
  box-shadow: $hm-card-shadow;

  &.anomalous {
    border-style: dashed;
    border-color: $hm-card-border-color-anomalous;
  }

  &.not-evaluated {
    opacity: 0.38;
  }

  .header {
    margin-bottom: 1rem;

    .title {
      color: $hm-card-title-color;
      font-size: $font-size-xl;
      font-weight: 600;
      margin: auto auto auto 0.5rem;
    }

  }

  .image {
    // margin-right: 0.5rem;

    img {
      max-width: 100%;
      max-height: $hm-card-image-max-height;
      border-radius: $radius-2;
    }

  }

  .not-found {
    background: $card-background;
    height: 100%;
    width: 100%;
    min-height: 120px;
    border-radius: $radius-3;

    span {
      font-weight: 500;
      color: $color-secondary;
    }
  }


}

// // // // // // // // // // // // 
// CLOTH MONITORING
// // // // // // // // // // // // 
mat-card.cloth-monitoring {
  margin: 0 auto;
  background: $card-background;
  border-radius: $radius-2;

  &.not-found {
    margin-top: 0.5rem;
    padding: 1rem;
    text-align: center;
  }

  &.cloth-card {
    border: 4px solid $card-background;

    .id {
      font-weight: bold;
      padding: 4px 0 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid $line-color-border-header;
    }

    .labels {
      color: $line-header-color;
    }

    .values {
      font-weight: bold;
      color: $color-primary;
      margin-top: 8px;
    }

    &.selected {
      border-color: $color-primary;
    }
  }

}


// // // // // // // // // // // // 
// REMOTE MONITORING - REPEATED CARD
// // // // // // // // // // // // 
.repeated-card {

  margin: $card-margin 0 $card-margin 0;
  padding: $card-padding;
  background: $card-background;
  border-radius: $radius-2;
  border-style: solid;
  border-width: $card-border-width;
  border-color: $card-border-color;
  color: $card-text-color;


  .header {
    margin-bottom: 1rem;
    margin-right: 4px;

    .title {
      color: $card-title-color;
      font-size: $card-title-font-size;
      font-weight: 600;
      margin: $card-title-margin;
    }

    .status {
      font-size: $font-size-s;
      border-radius: $radius-3;
      font-weight: bold;
      padding: 6px 8px;

      span.status-icon-container {
        margin-right: 4px;
      }

      &.run {
        background: $card-machine-status-1-background;
        color: $card-machine-status-1-color;
      }

      &.never {
        background: $card-machine-status-2-background;
        color: $card-machine-status-2-color;
      }

    }

  }

  table {

    mat-icon {
      font-size: $font-size;
      width: 2.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
    }

    width: 100%;
    border-collapse: collapse;

    tr th {
      text-align: initial;
      text-transform: capitalize;
      font-weight: 800;
      font-size: $font-size-s;
      border-bottom: 1px solid $line-color-border-header;
    }

    tr,
    tr td {
      font-weight: 700;
      // color: $line-body-color;
      font-size: $font-size-s;
      //   background: $line-background;
      border-bottom: 1px solid $line-color-border-header;
    }

    // tr:hover {
    //   background: $line-background-hover;
    // }

    th:first-of-type,
    td:first-of-type {
      padding-left: 8px;
    }

    th:not(:first-of-type),
    td:not(:first-of-type) {
      padding: 8px;
    }

  }

}


// // // // // // // // // // // // 
// INTERVAL CALENDAR
// // // // // // // // // // // // 
.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  // background: $interval-calendar-bg-color;
  color: $interval-calendar-font-color;
}

.mat-datepicker-toggle,
.mat-datepicker-content,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-calendar-table-header,
.mat-calendar-body-label {
  background: $interval-calendar-bg-color !important;
  color: $interval-calendar-font-color !important;
}

.mat-calendar-arrow {
  fill: $interval-calendar-font-color;
}

.mat-calendar-body-selected {
  background: $interval-calendar-selected-date-bg-color;
  color: $interval-calendar-selected-date-font-color;
}

.mat-calendar-body-in-range::before {
  background: $interval-calendar-selected-range-bg-color;
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: $interval-calendar-hover-date-bg-color;
}

@media(hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background: $interval-calendar-hover-date-bg-color;
  }
}

// // // // // // // // // // // // 
// VIDEO STREAMING
// // // // // // // // // // // // 
.ff-streaming-container {
  background: $color-white;
  border-radius: $radius-3;

  p {
    padding: 24px;
    margin: 0;
    font-weight: 500;
    font-size: $font-size-xl;
  }

  .title {
    font-size: $font-size-xl;
    font-weight: 800;
    color: #777E88;
    margin-bottom: 4px;
    letter-spacing: 0.5px;
  }

  .value {
    font-size: $font-size-l;
    font-weight: bold;
    opacity: 0.8;
    letter-spacing: 0.5px;
    // color: #777E88;
  }
}

// // // // // // // // // // // // 
// FILTERS DIALOG / SELECTION DIALOG
// // // // // // // // // // // // 
.max-limit-warning {
  span {
    font-weight: 600;
    color: #ff9900;
    padding: 4px 0;
  }
}

.video-container {
  margin: 0 8px 8px 8px;
  border-radius: $radius-2;
}

.modebar-group {
  .icon {
    width: $plotly-modebar-icons-width !important;
  }
}