@import "variables-base";

// scrollbar
$scrollbar-width: 6px;
$scrollbar-height: 4px;

// font
$font-main-size: 14px;
$font-main-family: "Poppins", "Roboto", "Helvetica Neue", sans-serif;
$font-main-family-toolbar-first: "Poppins", "Roboto", "Helvetica Neue", sans-serif;

$font-size-title: 1.5rem;
$font-size-subtitle: 1.3rem;

$font-size: 1rem;

$font-size-xxs: 0.7rem;
$font-size-xs: 0.8rem;
$font-size-s: 0.9rem;
$font-size-l: 1.1rem;
$font-size-xl: 1.2rem;
$font-size-xxl: 1.3rem;

$font-button-l: 1.2rem;
$line-height-button-l: 1.3rem;

$font-button-m: 1rem;
$line-height-button-m: 1.1rem;

// radius
$radius-1: 40px;
$radius-2: 20px;
$radius-3: 12px;

// sidenav
$sidenav-width: 240px;
$sidenav-width-collapsed: 80px;
$header-logo-padding: 2rem 1.25rem 1.25rem;
$header-logo-width: 100%;
$header-logo-height: 150px;
$sidenav-button-border-width: 0;

// main-toolbar
$main-toolbar-height: 36px;
$main-toolbar-pipe-border-display: none;

// tabs
$tabs-height: 40px;

// buttons
$button-l-height: 56px;
$button-m-height: 52px;
$button-s-height: 40px;
$button-xs-height: 26px;
$button-border: none;

// transitions
@mixin transition-01 {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

// homepage
$card-margin: 8px;
$card-padding: 16px;
$card-border-width: 3px;

// gauge
$gauge-rotation: rotate(-225deg);
$gauge-value-size: 1.75rem;
$gauge-value-weight: 600;
$gauge-label-size: 0.9rem;
$gauge-label-weight: 100;