@import "colors-base";

// background
$main-background: #F6F8F8;
$secondary-background: #F6F8F8;

// principal colors
$color-primary: #00E0B9;
$color-primary-025: rgba(82, 105, 251, 0.25);
$color-disabled: #A9ACBC;
$color-secondary: #21232B;

// scrollbar
$scrollbar-background: #777E88;
$scrollbar-color: #00E0B9;

// sidenav
$sidenav-background: #151414;
$sidenav-color: #777E88;
$sidenav-color-icon: #777E88;
$sidenav-border-color: transparent;

$sidenav-background-selected: #5f6161;
$sidenav-color-selected: #FFFFFF;
$sidenav-color-icon-selected: #00E0B9;
$sidenav-border-color-selected: transparent;

$sidenav-background-disabled: transparent;
$sidenav-color-icon-disabled: #3C3F44;
$sidenav-color-disabled: #3C3F44;
$sidenav-border-color-disabled: transparent;

$sidenav-arrow-background : #00E0B9;

$header-logo-background: #151414;
$header-close-color: #FFFFFF;

$sidenav-footer-voice-background: #26282B;
$sidenav-footer-voice-label-color: #777E88;
$sidenav-footer-voice-value-color: #FFFFFF;
$sidenav-footer-voice-icon-color: #00E0B9;

// toolbar
$toolbar-background: #F6F8F8;
$toolbar-color: #21232B;
$toolbar-color-2: #777E88;

// tabs
$tab-button-background: transparent;
$tab-button-color: #BFCACF;
$tab-button-color-icon: #BFCACF;

$tab-button-background-selected: #E8ECED;
$tab-button-color-selected: #151414;
$tab-button-color-icon-selected: #00E0B9;
$tab-button-shadow-selected: 0px 10px 50px rgba(130, 147, 252, 0.15);

$tab-button-background-disabled: transparent;
$tab-button-color-disabled: #E8ECED;
$tab-button-color-icon-disabled: #E8ECED;

$tab-content-border-color: #D6DDE0;
$tab-content-background: transparent;

// buttons
$button-background-1: #E8ECED;
$button-color-1: #3C3F44;

// filter-buttons
$filter-button-background: $color-primary;
$filter-button-color: #FFFFFF;
$filter-button-color-icon: #FFFFFF;

// dialog
$dialog-title-background: #FFFFFF;
$dialog-title-color: #3C3F44;
$cycle-selected-disabled: #777E88;
$cycle-selected: #777E88;

// $filter-button-background-selected: #E8ECED;
// $filter-button-color-selected: #151414;
// $filter-button-color-icon-selected: #00E0B9;
// $filter-button-shadow-selected: 0px 10px 50px rgba(130, 147, 252, 0.15);

// $filter-button-background-disabled: transparent;
// $filter-button-color-disabled: #E8ECED;
// $filter-button-color-icon-disabled: #E8ECED;

// homepage
$card-background: #FFFFFF;
$card-background-selected: #FFFFFF;
$card-border-color: transparent;
$card-border-color-selected: #00E0B9;
$card-shadow-selected: 0px 10px 50px rgba(130, 147, 252, 0.15);
$card-title-color: #151414;
$card-text-color: #151414;
$card-text-color-2: #777E88;

$card-box-background: #F6F8F8;
$card-box2-background: #FFFFFF;

$card-machine-status-1-background: #D5FBE5;
$card-machine-status-1-color: #4BDD7D;
$card-machine-status-2-background: #E8ECED;
$card-machine-status-2-color: #BFCACF;
$card-machine-status-3-color: #BFCACF;

$card-box2-icon-1: #151414;
$card-box2-icon-1-background: #8CF2E1;
$card-box2-icon-2-background: #E8ECED;
$card-box2-icon-3-background: #BFCACF;

$map-card-background: #FFFFFF;
$map-card-image-background: #E8ECED;

// table
$line-color-border-header: #E8ECED;
$line-color: #3C3F44;
$line-background: #FFFFFF;
$line-color-selected: #26282B;
$line-background-selected: #E8ECED;
$line-color-border: #E8ECED;
$line-header-color: #777E88;
$line-body-color: #3C3F44;
$line-background-hover: #F6F8F8;
$line-background-hover-2: #cdcfcf;

// remote monitoring
$datapoint-color: #F6F8F8;
$datapoint-link-background: #F6F8F8;
$datapoint-background: #151414;
$datapoint-value-color: #F6F8F8;

// gauge
$gauge-circle: #00E0B9;
$gauge-background: #FFFFFF;
$gauge-stroke-circle-back: #E8ECED;
$gauge-value-color: #3C3F44;
$gauge-label-color: #777E88;

// rectangle
$rectangle-bg-color: #F4F5FB;

// switch
$switch-color-on: #FFFFFF;
$switch-background-on: $color-primary;
$switch-color-off: #777E88;
$switch-background-off: #E8ECED;

// slider
$slider-bar-backgorund: #D6DDE0;

// rba
$rba-alert-border: 2px solid #FFFFFF;
$rba-admin-alert-border: 2px solid #00E0B9;
$rba-admin-alert-box-shadow: 0px 10px 20px rgb(119 126 136 / 80%);

// shift templates
$shift-templates-not-found-bg-color: #FFFFFF;
$shift-templates-not-found-font-color: #212b21;


// GRAY
$color-gray: #BFCACF;
// BLUE
$color-blue: #5B68E6;
// LIGHT BLUE
$color-light-blue: #2196f3;
// YELLOW
$color-yellow: #FFEC86;
// ORANGE
$color-orange: #FCA10D;
// RED
$color-red: #FC5249;
// GREEN
$color-green: $color-primary;
// WHITE
$color-white: #FFFFFF;


// COLOR CLASS GENERATION
$md-colors: "md-primary" $color-primary $color-white $color-white $color-primary,
"md-transparent"#ffffff00 #191919 #ffffff00 #191919,
"md-white"$color-white #191919 $color-white #191919,
"md-red"$color-red $color-white $color-red #FFB3B3,
"md-yellow"$color-yellow #3c3c3cde $color-yellow #FDF6CF,
"md-orange"$color-orange $color-white $color-orange #FEE3B9,
"md-blue-gray"#6b7c84 $color-white #6b7c84 $color-white,
"md-gray"$color-gray #323232 $color-gray #E8ECED,
"md-light-gray"#e1e1e1 #323232 #e1e1e1 #D6DDE0,
"md-green"$color-green $color-white $color-green #D5FBE5,
"md-light-blue"$color-light-blue $color-white $color-light-blue $color-white,
"md-blue"$color-blue $color-white $color-blue #9BB7FF,
;

@each $class-name,
$bg-color,
$font-color,
$font-color-i,
$bg-color-i in $md-colors {

    .#{$class-name} {

        background-color: $bg-color !important;
        color: $font-color !important;
        fill: $font-color !important;

        .icon {
            background-color: $bg-color !important;
            color: $font-color !important;
            fill: $font-color !important;
        }

        &.border {
            border-width: 2px;
            border: 2px solid $bg-color;
            background-color: transparent !important;
            color: $bg-color !important;

            .icon {
                background-color: transparent !important;
                color: $bg-color !important;
                fill: $bg-color !important;
            }

        }
    }

    p.#{$class-name},
    span.#{$class-name},
    .icon.#{$class-name} {
        background-color: transparent !important;
        color: $bg-color !important;
        fill: $bg-color !important;

        &.border {
            border-width: 2px;
            border: 2px solid $bg-color;
            background-color: transparent !important;
            color: $bg-color !important;
        }
    }

    .#{$class-name}-i,
    .#{$class-name}-i .icon {

        background-color: $bg-color-i !important;
        color: $font-color-i !important;
        fill: $font-color-i !important;
    }

    p.#{$class-name}-i,
    span.#{$class-name}-i,
    .icon.#{$class-name}-i {
        background-color: transparent !important;
        color: $bg-color-i !important;
        fill: $bg-color-i !important;
    }

}