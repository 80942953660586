// scrollbar
$scrollbar-width: 6px;
$scrollbar-height: 4px;

// font
$font-main-size: 16px;
$font-main-family: "Montserrat", "Roboto", "Helvetica Neue", sans-serif;
$font-main-family-toolbar-first: "Montserrat", "Roboto", "Helvetica Neue", sans-serif;

$font-size-title: 1.5rem;
$font-size-subtitle: 1.3rem;

$font-size: 1rem;

$font-size-xxs: 0.7rem;
$font-size-xs: 0.8rem;
$font-size-s: 0.9rem;
$font-size-l: 1.1rem;
$font-size-xl: 1.2rem;
$font-size-xxl: 1.3rem;
$font-size-supreme: 1.5rem;
$font-size-main-error: 1.8rem;

$font-size-remote-monitoring-header: $font-size-xxs;
$font-size-remote-monitoring-table: $font-size-xs;
$font-size-remote-monitoring-value: $font-size-s;


$font-button-l: 1.2rem;
$line-height-button-l: 1.3rem;

$font-button-m: 1rem;
$line-height-button-m: 1.1rem;

// radius
$radius-0: 50%;
$radius-1: 40px;
$radius-2: 20px;
$radius-3: 12px;

// sidenav
$sidenav-width: 240px;
$sidenav-width-collapsed: 80px;
$header-logo-padding: 2rem 1.25rem 0.75rem;
$header-logo-width: 100%;
$header-logo-height: 150px;
$sidenav-button-border-width: 0;
$sidenav-button-border-height: 0;
$sidenav-button-border-margin-left: 0;

// main-toolbar
$main-toolbar-height: 72px;
$main-toolbar-pipe-border-display: none;

// tabs
$tabs-height: 40px;

// buttons
$button-l-height: 72px;
$button-m-height: 52px;
$button-s-height: 44px;

// transitions
@mixin transition-01 {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

// homepage
$card-margin: 8px;
$card-padding: 16px;
$card-border-width: 3px;
$card-title-margin: auto auto auto 0.5rem;
$card-title-font-size: 1.2rem;

// gauge
$gauge-rotation: rotate(-225deg);
$gauge-value-size: 1.75rem;
$gauge-value-weight: 600;
$gauge-label-size: 0.9rem;
$gauge-label-weight: 100;

// health monitoring card
$hm-card-margin: 8px;
$hm-card-padding: 16px;
$hm-card-border-width: 3px;
$hm-card-image-max-height: 250px;


// Filter buttons
$filter-button-font-weight: 500;

// Tab button
$tab-button-margin: 0 0.75rem 0.75rem 0;
$tab-button-padding: 0 0.75rem;

// Scrollbar
$scrollbar-margin-top: 0;
$scrollbar-margin-bottom: 0;

// Plotly modebar icons
$plotly-modebar-icons-width: 0.9rem;