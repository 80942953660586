// background
$main-background: #FFFFFF;
$secondary-background: #F4F5FB;
$card-background: #FFFFFF;

// principal colors
$color-primary: #5269FB;
$color-primary-025: rgba(82, 105, 251, 0.25);
$color-disabled: #A9ACBC;
$color-secondary: #21232B;
$color-tertiary: #E40521;

// scrollbar
$scrollbar-background: #F4F5FB;
$scrollbar-color: #5269FB;

// sidenav
$sidenav-background: #FFFFFF;
$sidenav-color: #70758F;
$sidenav-color-icon: #70758F;
$sidenav-border-color: transparent;

$sidenav-background-selected: #F4F5FB;
$sidenav-color-selected: #21232B;
$sidenav-color-icon-selected: #21232B;
$sidenav-border-color-selected: #5269FB;

$sidenav-background-disabled: transparent;
$sidenav-color-icon-disabled: #D4D6DE;
$sidenav-color-disabled: #D4D6DE;
$sidenav-border-color-disabled: transparent;

$header-logo-background: #FFFFFF;
$header-close-color: #21232B;

$sidenav-footer-voice-background: #26282B;
$sidenav-footer-voice-label-color: #777E88;
$sidenav-footer-voice-value-color: #FFFFFF;
$sidenav-footer-voice-icon-color: #00E0B9;

$sidenav-arrow-color: #191919;
$sidenav-arrow-background: #191919;

// toolbar
$toolbar-color: #142459;
$toolbar-color: #21232B;
$toolbar-color-2: #777E88;
$toolbar-background: #777E88;

$sidenav-arrow-background : $toolbar-color;

// language-toolbar
$language-toolbar-background: $sidenav-background;
$language-toolbar-color: $sidenav-color;

// tabs
$tab-button-background: transparent;
$tab-button-color: #BFCACF;
$tab-button-color-icon: #BFCACF;
$tab-button-opacity-selected: 1.0;
$tab-button-opacity: 1.0;
$tab-border-bottom-selected: 0px solid transparent;

$tab-button-background-selected: #FFFFFF;
$tab-button-color-selected: #5269FB;
$tab-button-color-icon-selected: #5269FB;
$tab-button-shadow-selected: 0px 10px 50px rgba(130, 147, 252, 0.15);
$tab-button-inset-shadow: transparent;

$tab-button-background-disabled: transparent;
$tab-button-color-disabled: #3C3F44;
$tab-button-color-icon-disabled: #3C3F44;

$tab-content-border-color: #D6DDE0;
$tab-content-background: transparent;

// buttons
$button-background-1: #E8ECED;
$button-background-2: #81878a;
$button-color-1: #3C3F44;
$button-color-2: #FFFFFF;

// filter-buttons
$filter-button-background: $color-primary;
$filter-button-color: #FFFFFF;
$filter-button-color-icon: #FFFFFF;

// dialog
$dialog-title-background: $color-primary;
$dialog-title-color: $color-secondary;
$dialog-body-bg-color: #FFFFFF;
$dialog-body-component-detail-bg-color: #F6F8F8;
$dialog-body-font-color: #000000DE;
$dialog-close: transparent;

// cycle-selected
$cycle-selected-disabled: #8f8f8f;
$cycle-selected: #8f8f8f;

// radio-button
$radio-button-out: #E1FAEB;
$radio-button-in: #4BDD7D;
$radio-button-disabled-out: #F6F8F8;
$radio-button-disabled-in: #D6DDE0;

// state container
$state-container-font-color: #373A3F;

// machine recorder events
$machine-recorder-events-title-font-color: #FFFFFF;
$machine-recorder-events-title-bg-color: #4A4E54;
$machine-recorder-events-body-font-color: #777E88;
$machine-recorder-events-body-bg-color: #FFFFFF;
$machine-recorder-events-icon: #2FD0CB;

// homepage
$homepage-suggestion-span-font-color: #000000;

$card-background: #FFFFFF;
$card-background-selected: #FFFFFF;
$card-border-color: transparent;
$card-border-color-selected: #5269FB;
$card-shadow-selected: 0px 10px 50px rgba(130, 147, 252, 0.15);
$card-title-color: #21232B;
$card-text-color: #21232B;
$card-text-color-2: #777E88;

$card-image-background: transparent;

$card-box-background: #F6F8F8;
$card-box2-background: #FFFFFF;

$card-machine-status-1-background: #D5FBE5;
$card-machine-status-1-color: #5269FB;
$card-machine-status-2-background: #E8ECED;
$card-machine-status-2-color: #BFCACF;
$card-machine-status-3-color: #BFCACF;

$card-box2-icon-1: #FFFFFF;
$card-box2-icon-1-background: #8CF2E1;
$card-box2-icon-2-background: #E8ECED;
$card-box2-icon-3-background: #BFCACF;

$map-card-background: #FFFFFF;
$map-card-font-color: #000000;
$map-card-image-background: #E8ECED;

// table
$line-color-border-header: #BFCACF;
$line-color: #3C3F44;
$line-background: #FFFFFF;
$line-color-selected: #26282B;
$line-background-selected: #E8ECED;
$line-color-border: #E8ECED;
$line-header-color: #777E88;
$line-header-bg-color: #FFFFFF;
$line-body-color: #3C3F44;
$line-background-hover: #F6F8F8;
$line-background-hover-2: #cdcfcf;
$line-background-trained: #40a4f461;

// remote monitoring
$datapoint-color: #FFFFFF;
$datapoint-link-background: #F6F8F8;
$datapoint-link-color: #0a0a0a;
$datapoint-background: #151414;
$datapoint-value-color: #777E88;

// gauge
$gauge-circle: #5269FB;
$gauge-background: #EBECEF;
$gauge-stroke-circle-back: #E8ECED;
$gauge-value-color: #3C3F44;
$gauge-label-color: #777E88;

// rectangle
$rectangle-bg-color: #F4F5FB;

// switch
$switch-color-on: #FFFFFF;
$switch-background-on: $color-primary;
$switch-color-off: #777E88;
$switch-background-off: #E8ECED;
$switch-border: 1px solid #ffffff57;

// slider
$slider-bar-backgorund: #D6DDE0;

// health monitoring card
$hm-card-background: #FFFFFF;
$hm-card-border-color: transparent;
$hm-card-border-color-anomalous: #FFB3B3;
$hm-card-title-color: #21232B;
$hm-card-text-color: #21232B;
$hm-card-text-color-2: #777E88;
$hm-card-shadow: 0px 10px 50px rgba(119, 126, 136, 0.1);

// No data available (div)
$data-not-found-bg-color: white;
$data-not-found-font-color: #0a0a0a;

// search-input
$search-input-bg-color: #FFFFFF;
$search-input-font-color: #BFCACF;

// ff-input-select
$input-select-bg-color: #EBEEEF;
$input-select-font-color: #000000;

// selector
$selector-bg-color: #FFFFFF;
$selector-bg-color-alternative: #F6F8F8;
$selector-label-font-color: #BFCACF;
$selector-value-font-color: #777E88;
$selector-divider-color: #F6F8F8;
$selector-selected-font-color: #eeeeee;
$selector-bg-color-disabled: #FFFFFF;
$selector-value-font-color-disabled: #777E8870;

// ff-bar
$ff-bar-bg-color: #EBECEF;
$ff-bar-label-2-color: #000000;

// loading-card
$loading-card-bg-color: #FFFFFF;

// widget-value
$widget-value-title-font-color: #000000;
$widget-value-span-font-color: #000000;

// ff-input
$ff-input-font-color: #000000;

// line-critical
$line-color-critical: #FFFFFF;
$line-background-critical: #FF5757;

// line-subcritical
$line-color-subcritical: #FFFFFF;
$line-background-subcritical: #FFC059;

// line-third-color
$line-color-third-color: #FFFFFF;
$line-background-third-color: #868d8d;

// rba
$rba-card-bg-color: #F6F8F8;
$rba-alert-border: 2px solid white;
$rba-admin-alert-border: 2px solid red;
$rba-admin-alert-box-shadow: 0px 10px 20px rgb(119 126 136 / 80%);

// calendar
$calendar-bg-color: #FFFFFF;
$calendar-font-color: #000000;

// interval calendar
$interval-calendar-bg-color: #FFFFFF;
$interval-calendar-font-color: #000000de;
$interval-calendar-hover-date-bg-color: rgba(103, 58, 183, .3);
$interval-calendar-selected-date-bg-color: #673ab7;
$interval-calendar-selected-date-font-color: #FFFFFF;
$interval-calendar-selected-range-bg-color: #673ab733;

// mat-menu (settings in toolbar dropdown)
$mat-menu-bg-color: #FFFFFF;
$mat-menu-font-color: #000000de;
$mat-menu-icon-color: #0000008a;


// PRIMARY
.md-primary,
.md-primary .icon {
  background: $color-primary;
  color: #FFFFFF;
  fill: #FFFFFF;
}

p.md-primary,
span.md-primary,
.icon.md-primary {
  background: transparent;
  color: $color-primary;
}

// WHITE
$color-white: #FFFFFF;

.md-white,
.md-white .icon {
  background: $color-white;
  color: $color-secondary;
  fill: $color-secondary;
}

p.md-white,
span.md-white,
.icon.md-white {
  background: transparent;
  color: $color-white;
}

// GREEN
$color-green: #4BDD7D;

.md-green,
.md-green .icon {
  background: #D5FBE5;
  color: $color-green;
  fill: $color-green;
}

p.md-green,
span.md-green,
.icon.md-green {
  background: transparent;
  color: $color-green;
}

// GREEN-INVERTED    
.md-green-i,
.md-green-i .icon {
  background: $color-green !important;
  color: $color-white !important;
  fill: $color-white !important;
}

p.md-green-i,
span.md-green-i,
.icon.md-green-i {
  background: transparent !important;
  color: $color-white !important;
}

// RED
$color-red: #FC5249;

.md-red,
.md-red .icon {
  background: #FFB3B3;
  color: $color-red;
  fill: $color-red;
}

p.md-red,
span.md-red,
.icon.md-red {
  background: transparent;
  color: $color-red;
}

// ORANGE
$color-orange: #FCA10D;

.md-orange,
.md-orange .icon {
  background: #FEE3B9;
  color: $color-orange;
  fill: $color-orange;
}

p.md-orange,
span.md-orange,
.icon.md-orange {
  background: transparent;
  color: $color-orange;
}

// YELLOW
$color-yellow: #FFEC86;

.md-yellow,
.md-yellow .icon {
  background: #FDF6CF;
  color: $color-yellow;
  fill: $color-yellow;
}

p.md-yellow,
span.md-yellow,
.icon.md-yellow {
  background: transparent;
  color: $color-yellow;
}

// BLUE
$color-blue: #5B68E6;

.md-blue,
.md-blue .icon {
  background: #9BB7FF;
  color: $color-blue;
  fill: $color-blue;
}

p.md-blue,
span.md-blue,
.icon.md-blue {
  background: transparent;
  color: $color-blue;
}

// GRAY
$color-gray: #BFCACF;

.md-gray,
.md-gray .icon {
  background: #E8ECED;
  color: $color-gray;
  fill: $color-gray;
}

p.md-gray,
span.md-gray,
.icon.md-gray {
  background: transparent;
  color: $color-gray;
}

// ORANGE-INVERTED    
.md-orange-i,
.md-orange-i .icon {
  background: $color-orange;
  color: $color-white;
  fill: $color-white;
}

p.md-orange-i,
span.md-orange-i,
.icon.md-orange-i {
  background: transparent;
  color: $color-white;
}

// RED-INVERTED    
.md-red-i,
.md-red-i .icon {
  background: $color-red;
  color: $color-white;
  fill: $color-white;
}

p.md-red-i,
span.md-red-i,
.icon.md-red-i {
  background: transparent;
  color: $color-white;
}

// GRAY-INVERTED    
.md-gray-i,
.md-gray-i .icon {
  background: $color-gray;
  color: $color-white;
  fill: $color-white;
}

p.md-gray-i,
span.md-gray-i,
.icon.md-gray-i {
  background: transparent;
  color: $color-white;
}

// GRAY-INVERTED 2  
.md-gray-i-2,
.md-gray-i-2 .icon {
  background: #D6DDE0;
  color: $color-white;
  fill: $color-white;
}

p.md-gray-i-2,
span.md-gray-i-2,
.icon.md-gray-i-2 {
  background: transparent;
  color: $color-white;
}

.md-violet {
  background: #faf3ff;
}